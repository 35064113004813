import {
  UPDATE_MEDIA_CLEAR_ERROR,
  UPDATE_MEDIA_CLEAR_STATE,
  UPDATE_MEDIA_FAIL,
  UPDATE_MEDIA_REQUEST,
  UPDATE_MEDIA_SUCCESS,
} from "../constant/kycConstant";

export const kycUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MEDIA_REQUEST:
      return {
        loading: true,
        kycData: {},
      };
    case UPDATE_MEDIA_SUCCESS:
      return {
        loading: false,
        kycData: action.payload,
      };
    case UPDATE_MEDIA_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case UPDATE_MEDIA_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case UPDATE_MEDIA_CLEAR_STATE:
      return {
        loading: false,
        kycData: {},
      };

    default:
      return state;
  }
};
