import React, { Fragment, useEffect } from "react";
import CourseCard from "./CourseCard";
import "../../styles/courseList.scss";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import {
  clearCourseErrors,
  getAllCourses,
} from "../../redux/action/courseAction";
import Loader from "../reusable/Loader";

const CourseList = () => {
  const dispatch = useDispatch();

  // SLIDER SETTING START
  const CustomPrevArrow = ({ currentSlide, slideCount, ...props }) => {
    const buttonProps = { ...props };

    return (
      <button {...buttonProps} className="slick-arrow slick-prev">
        <BiChevronsLeft />
      </button>
    );
  };

  const CustomNextArrow = ({ currentSlide, slideCount, ...props }) => {
    const buttonProps = { ...props };

    return (
      <button {...buttonProps} className="slick-arrow slick-next">
        <BiChevronsRight />
      </button>
    );
  };

  // GET ALL COURSES START
  // GET ALL COURSES START
  const [, , showNotification] = useNotification();

  const {
    loading,
    error,
    courses: data,
  } = useSelector((state) => state.getAllCourses);

  // console.log("data", data);

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearCourseErrors());
    }
  }, [dispatch, error, showNotification]);

  // GET ALL COURSES ENDS
  // GET ALL COURSES ENDS

  // WIDTH FOR ALL COURSES AND SETTING FOR THAT

  const AllCourseSlider = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: data?.courses?.length > 2 ? 3 : data?.courses?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: data?.courses?.length > 1 ? 2 : data?.courses?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  let AllSliderWidth = "100%";
  let AllSliderWidthMobile = "100%";

  if (data?.courses?.length < 3) {
    AllSliderWidth = `${33 * data?.courses?.length}%`;
  }
  if (data?.courses?.length < 2 && window.innerWidth < 768) {
    AllSliderWidthMobile = `${100}%`;
  }

  return (
    <Fragment>
      <div className="course_list">
        {loading ? (
          <div className="loader">
            <h2>Loading ....</h2>
            <p>Please wait we get the result</p>
          </div>
        ) : (
          <div
            className="slider-container"
            style={{
              width:
                window.innerWidth >= 991
                  ? AllSliderWidth
                  : AllSliderWidthMobile,
            }}
          >
            <Slider {...AllCourseSlider}>
              {data?.courses?.length &&
                data?.courses?.map((ele) => {
                  return <CourseCard data={ele} key={ele._id} />;
                })}
            </Slider>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CourseList;
