import { axiosInstance } from "../../utils/axiosInstance";
import {
  ALL_COURSES_FAIL,
  ALL_COURSES_REQUEST,
  ALL_COURSES_SUCCESS,
  CLEAR_COURSE_ERRORS,
  CLEAR_COURSE_STATE,
  COURSE_DETAIL_FAIL,
  COURSE_DETAIL_REQUEST,
  COURSE_DETAIL_SUCCESS
} from "../constant/courseConstant";


// GET ALL COURSES
export const getAllCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_COURSES_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/courses", config);

    dispatch({
      type: ALL_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_COURSES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET COURSE DETAIL BY ID
export const getCourseDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_DETAIL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/course/${id}`, config);

    dispatch({
      type: COURSE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};


// CLEAR ERROR ACTION FUNCTION
export const clearCourseErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_COURSE_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const clearCourseState = () => async (dispatch) => {
  dispatch({ type: CLEAR_COURSE_STATE });
};
