import React from 'react';
import styled, { keyframes } from 'styled-components';

const marqueeAnimation = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
`;

const MarqueeWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  background:linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%);
//   border:1px solid rgba(255, 255, 255, 0.08);
  position:relative;
  .marquee_content{
    overflow: hidden;
    white-space: nowrap;
    display:flex;
  }

 
`;
const MarqueeThumb = styled.div`
  flex-shrink: 0; 
  width: 160px;
  display: flex;
  padding:16px 0px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-transform:uppercase;
  letter-spacing:0.6px;
  z-index:1;
  position:absolute;
  left:0;
  font-weight: 600;
  font-family: poppins;
  color: #f2f2f2;
  background:linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%);
  @media(max-width:767px){
    padding:12px;
    width: auto;
    letter-spacing:0.4px;
  }
`;
const MarqueeInner = styled.div`
  display: inline-block;
  font-size:14px;
  font-family:poppins;
  padding:16px 0px;
  color:#f2f2f2;
  animation: ${marqueeAnimation} 16s linear infinite;
  @media(max-width:767px){
    padding:12px 0px;
  }
  &:hover {
    .marquee-inner {
      animation-play-state: paused;
    }
  }
`;

const Marquee = () => {
    return (
        <MarqueeWrap>
            <div className='container'>
                <div className='marquee_content'>
                    <MarqueeThumb>
                        Newsflash
                    </MarqueeThumb>
                    <MarqueeInner>
                        {/* {text} */}
                        Recognized by Government of India NCVT | An Institute For All Pre Entrance Examination Coaching Center
                    </MarqueeInner>
                </div>

            </div>

        </MarqueeWrap>
    );
};

export default Marquee;
