import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f5fb;
  > .container {
    width: 100%;
  }
`;

const SignUpWrapper = styled.div`
  width: 100%;
  min-height: 500px;
  background-color: #fafad8;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media(max-width:767px){
    display: grid;
    grid-template-columns: 1fr;
    min-height: 350px;
  }
  >.img_section{
    padding:30px;
    background-color: #02298A;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:767px){
       display:none;
    }
    >div{
        >p{
            font-size: 20px;
            font-family: poppins;
            color: #f2f2f2;
            line-height: 1.5;
            margin-bottom:20px;
        }
        >h2{
            font-size: 32px;
            font-family: nunito;
            color: #f2f2f2;
            line-height: 1.5;
            margin-bottom:40px;
            @media(max-width:767px){
                font-size:24px;
                margin-bottom:30px;
            }
        }
        >a{
            background-color:#f2f2f2;
            border-radius: 8px;
            font-size:18px;
            font-family:poppins;
            font-weight:500;
            padding: 10px;
            color:#02298A;
            border: 1px solid #f2f2f2;
            border-radius:40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            line-height:0;
            height: 40px;
            transition: background-color 0.2s ease-in-out;
                &:hover{
                    background-color: #02298A;
                    color: #f2f2f2;
                }
        }
    }
}
  }
`;

const SignUpLayout = ({ children }) => {
  return (
    <Wrapper>
      <div className="container">
        <SignUpWrapper>
          {children}
          <div className="img_section">
            <div>
              <p>Welcome</p>
              <h2>We are glad to see you</h2>
              <Link to="/">Go to home</Link>
            </div>
          </div>
        </SignUpWrapper>
      </div>
    </Wrapper>
  );
};

export default SignUpLayout;
