export const PURCHASE_REQUEST = "PURCHASE_REQUEST";
export const PURCHASE_SUCCESS = "PURCHASE_SUCCESS";
export const PURCHASE_FAIL = "PURCHASE_FAIL";

export const PURCHASE_DONE_REQUEST = "PURCHASE_DONE_REQUEST";
export const PURCHASE_DONE_SUCCESS = "PURCHASE_DONE_SUCCESS";
export const PURCHASE_DONE_FAIL = "PURCHASE_DONE_FAIL";

export const PURCHASE_LIST_REQUEST = "PURCHASE_LIST_REQUEST";
export const PURCHASE_LIST_SUCCESS = "PURCHASE_LIST_SUCCESS";
export const PURCHASE_LIST_FAIL = "PURCHASE_LIST_FAIL";

export const CLEAR_PURCHASE_STATE = "CLEAR_PURCHASE_STATE";
export const CLEAR_PURCHASE_ERROR = "CLEAR_PURCHASE_ERROR";
