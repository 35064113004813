import React from "react";
import Layout from "../../components/layouts/Layout";

const RefundPolicy = () => {
  return (
    <Layout>
      <div className="policy_wrapper">
        <div className="container">
          <div className="policy_content">
            <div className="policy_text">
              <h2 className="main_heading txt_center txt_color">
                Refund Policy
              </h2>
              <p>
                We understand that circumstances may change, and students may
                need to request a refund for their tuition fees. Our refund
                policy outlines the conditions under which refunds are granted,
                including withdrawal from courses within a specified period,
                cancellation of classes by the institution, or other exceptional
                circumstances. Refund processing times and applicable
                deductions, if any, are also clearly communicated to students.
              </p>
              <p>
                In the event that a course or class is canceled by the
                institution due to unforeseen circumstances such as insufficient
                enrollment, instructor availability, or other reasons beyond our
                control, students will be entitled to a full refund of their
                tuition fees for the affected course.
              </p>
              <p>
                Some fees and charges may be non-refundable, including but not
                limited to registration fees, assessment fees, or fees for
                supplementary services such as certification exams or
                specialized materials. These non-refundable fees are clearly
                indicated to students prior to enrollment or payment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RefundPolicy;
