import {
  ALL_EXAM_FAIL,
  ALL_EXAM_REQUEST,
  ALL_EXAM_SUCCESS,
  CLEAR_EXAM_ERRORS,
  CLEAR_EXAM_STATE,
  CREATE_EXAM_FAIL,
  CREATE_EXAM_REQUEST,
  CREATE_EXAM_SUCCESS,
  PRINT_HALL_TICKET_FAIL,
  PRINT_HALL_TICKET_REQUEST,
  PRINT_HALL_TICKET_SUCCESS,
} from "../constant/examConstant";

export const examCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_EXAM_REQUEST:
      return {
        loading: true,
        examData: {},
      };
    case CREATE_EXAM_SUCCESS:
      return {
        loading: false,
        examData: action.payload,
      };
    case CREATE_EXAM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_EXAM_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_EXAM_STATE:
      return {
        loading: false,
        examData: {},
      };

    default:
      return state;
  }
};

// GET ALL COURSES REDUCER
export const getAllExamReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_EXAM_REQUEST:
      return {
        loading: true,
        examList: {},
      };
    case ALL_EXAM_SUCCESS:
      return {
        loading: false,
        examList: action.payload,
      };
    case ALL_EXAM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_EXAM_ERRORS:
      return {
        examList: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const printHallTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case PRINT_HALL_TICKET_REQUEST:
      return {
        loading: true,
        printData: {},
      };
    case PRINT_HALL_TICKET_SUCCESS:
      return {
        loading: false,
        printData: action.payload,
      };
    case PRINT_HALL_TICKET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_EXAM_ERRORS:
      return {
        printData: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
