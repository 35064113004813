import {
  DOWNLOAD_STUDENT_INVOICE_FAIL,
  DOWNLOAD_STUDENT_INVOICE_REQUEST,
  DOWNLOAD_STUDENT_INVOICE_SUCCESS,
  FRANCHISE_STUDENT_CLEAR_ERROR,
  FRANCHISE_STUDENT_CLEAR_STATE,
  FRANCHISE_STUDENT_REGISER_FAIL,
  FRANCHISE_STUDENT_REGISER_REQUEST,
  FRANCHISE_STUDENT_REGISER_SUCCESS,
  GET_ALL_FRANCHISE_STUDENTS_FAIL,
  GET_ALL_FRANCHISE_STUDENTS_REQUEST,
  GET_ALL_FRANCHISE_STUDENTS_SUCCESS,
  GET_FRANCHISE_STUDENT_FAIL,
  GET_FRANCHISE_STUDENT_REQUEST,
  GET_FRANCHISE_STUDENT_SUCCESS,
  UPDATE_FRANCHISE_STUDENT_FAIL,
  UPDATE_FRANCHISE_STUDENT_REQUEST,
  UPDATE_FRANCHISE_STUDENT_SUCCESS,
} from "../constant/franchiseStudentConstant";

// FOR USER REGISTRATION
export const franchiseStudentRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case FRANCHISE_STUDENT_REGISER_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case FRANCHISE_STUDENT_REGISER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case FRANCHISE_STUDENT_REGISER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case FRANCHISE_STUDENT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FRANCHISE_STUDENT_CLEAR_STATE:
      return {
        loading: false,
        userInfo: {},
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL USER PROFILE REDUCER
export const getAllFranchiseStudentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_FRANCHISE_STUDENTS_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case GET_ALL_FRANCHISE_STUDENTS_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case GET_ALL_FRANCHISE_STUDENTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case FRANCHISE_STUDENT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// DOWNLOAD STUDENT INVOICE
export const downloadStudentInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_STUDENT_INVOICE_REQUEST:
      return {
        loading: true,
        studentInfo: {},
      };
    case DOWNLOAD_STUDENT_INVOICE_SUCCESS:
      return {
        loading: false,
        studentInfo: action.payload,
      };
    case DOWNLOAD_STUDENT_INVOICE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case FRANCHISE_STUDENT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const getFranchiseStudentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FRANCHISE_STUDENT_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case GET_FRANCHISE_STUDENT_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case GET_FRANCHISE_STUDENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case FRANCHISE_STUDENT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// update user profile
export const updateFranchiseStudentReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FRANCHISE_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FRANCHISE_STUDENT_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case UPDATE_FRANCHISE_STUDENT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case FRANCHISE_STUDENT_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case FRANCHISE_STUDENT_CLEAR_STATE:
      return {
        loading: false,
        userInfo: null,
      };

    default:
      return state;
  }
};
