import React, { Fragment } from "react";
import styled from "styled-components";
import FranchiseHeader from "./FranchiseHeader";
const LayoutWrapper = styled.div`
  min-height: 79vh;
`;
const FranchiseLayout = ({ children }) => {
  return (
    <Fragment>
      <FranchiseHeader />
      <LayoutWrapper>{children}</LayoutWrapper>
    </Fragment>
  );
};

export default FranchiseLayout;
