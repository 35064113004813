import { axiosInstance } from "../../utils/axiosInstance";
import {
  UPDATE_MEDIA_CLEAR_ERROR,
  UPDATE_MEDIA_CLEAR_STATE,
  UPDATE_MEDIA_FAIL,
  UPDATE_MEDIA_REQUEST,
  UPDATE_MEDIA_SUCCESS,
} from "../constant/kycConstant";

export const updateKyc = (name, logo, signature) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MEDIA_REQUEST,
    });
    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      "/update-media",
      {
        name,
        logo,
        signature,
      },
      config
    );
    dispatch({
      type: UPDATE_MEDIA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MEDIA_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const kycClearErrors = () => async (dispatch) => {
  dispatch({ type: UPDATE_MEDIA_CLEAR_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const kycClearState = () => async (dispatch) => {
  dispatch({ type: UPDATE_MEDIA_CLEAR_STATE });
};
