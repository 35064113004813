import {
  CLEAR_WALLET_TRANSACTION_ERROR,
  CLEAR_WALLET_TRANSACTION_STATE,
  GET_SELF_CREATED_TRANSACTION_FAIL,
  GET_SELF_CREATED_TRANSACTION_REQUEST,
  GET_SELF_CREATED_TRANSACTION_SUCCESS,
  GET_WALLET_FAIL,
  GET_WALLET_REQUEST,
  GET_WALLET_SUCCESS,
  RECHARGE_WALLET_FAIL,
  RECHARGE_WALLET_REQUEST,
  RECHARGE_WALLET_SUCCESS,
} from "../constant/walletConstant";
import { axiosInstance } from "../../utils/axiosInstance";
// FOR USER REGISTRATION
export const rechargeWalletAction =
  (amount, txnId, paidBy) => async (dispatch) => {
    try {
      dispatch({
        type: RECHARGE_WALLET_REQUEST,
      });
      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/fund-request",
        {
          amount,
          txnId,
          paidBy,
        },
        config
      );
      dispatch({
        type: RECHARGE_WALLET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RECHARGE_WALLET_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const getSelfTransactionAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELF_CREATED_TRANSACTION_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/my-txn", config);
    dispatch({
      type: GET_SELF_CREATED_TRANSACTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SELF_CREATED_TRANSACTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getWalletMoney = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_WALLET_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/wallet", config);
    dispatch({
      type: GET_WALLET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_WALLET_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearWalletErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_WALLET_TRANSACTION_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearWalletState = () => async (dispatch) => {
  dispatch({ type: CLEAR_WALLET_TRANSACTION_STATE });
};
