import React from 'react';
import "../../styles/aboutus.scss";
import Layout from '../../components/layouts/Layout';
// import {
//   FaPassport
// } from "react-icons/fa";
// import {
//   HiMiniBuildingOffice2
// } from "react-icons/hi2";
import styled from 'styled-components';
import Breadcrumb from '../../components/layouts/Breadcrumb';

const AboutWrapper = styled.div`

`
const AboutUs = () => {
  return (
    <Layout>
      <Breadcrumb title={"About Us"} url={"/about-us"} />
      <AboutWrapper>
        <section className='about_us_wrapper'>
          <div className='bg_blur'></div>
          <div className='container'>
            <div className='header'>
              <p className='heading_top'>
                About NIOTAM
              </p>
              <div className='heading_text'>
                <h2 className='main_heading'>
                  We Provide solution for <span> learning problems</span>
                </h2>
              </div>

            </div>
            <div className="about_content">
              <div className='about_content_text'>
                {/* <div className='icon_heading'>
                  <FaPassport />
                  <h4>
                    Our Mission
                  </h4>
                </div> */}
                <div className='text_content'>
                  <span>
                    <p>
                      National Institute  Of Technology And Management
                      has been registered Under the Government of India,
                      Working in different fields of Online Computer Course,
                      Skill Courses & Commercial Training .
                      The Organization is also certified by ISO 9001: 2015.
                      The Institute & the Organization is also Registered under
                      Department of Labour, Govt. of India, Ministry of Micro,
                      Small & Medium Enterprises (MSME), Department of Haryana
                      Labour, SKILL INDIA Digital, International Accreditation
                      Forum and is accredited to conduct the various fields of
                      Computer Courses & Programs Sponsored by both the Govt.
                    </p>
                    <p>
                      Dear friends, Our Computer education Courses are among
                      the top in the state. We provide all types of Diploma
                      and Certificate Course
                      from various Hand-picked Institutes across India.
                    </p>
                  </span>
                </div>

              </div>
              {/* <div className='about_content_text'>
                <div className='icon_heading'>
                  <HiMiniBuildingOffice2 />
                  <h4>
                    Our Vision
                  </h4>
                </div>
                <div className='text_content'>
                  <p>
                    The leading global provider of immigration and visa services
                    with the required reach, agility and client commitment to
                    enable corporations
                    and individuals to more easily navigate complex regulations.
                  </p>
                </div>

              </div> */}
              {/* <div className='about_content_text'>
                <div className='icon_heading'>
                  <FaPassport />
                  <h4>
                    Our Strategy
                  </h4>
                </div>
                <div className='text_content'>
                  <p>
                    The leading global provider of immigration and visa services
                    with the required reach, agility and client commitment to
                    enable corporations
                    and individuals to more easily navigate complex regulations.
                  </p>
                </div>

              </div> */}
            </div>
          </div>
        </section>
      </AboutWrapper>

    </Layout>
  )
}

export default AboutUs