import { axiosInstance } from "../../utils/axiosInstance";
import {
  CLEAR_PASSWORD_UPDATE_STATE,
  CLEAR_UPDATE_STATE,
  
  REMOVE_AVATAR_FAIL,
  REMOVE_AVATAR_REQUEST,
  REMOVE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAIL,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  USER_CLEAR_ERROR,
  USER_CLEAR_STATE,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_PASSWORD_UPDATE_FAIL,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_REGISER_FAIL,
  USER_REGISER_REQUEST,
  USER_REGISER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../constant/userConstant";

// FOR USER REGISTRATION
export const userRegisterAction =
  (
    fullName,
    password,
    email,
    phone,
    city,
    state,
    pinCode,
    village,
    fatherName,
    dob,
    gender,
    avatar,
    courseId,
    batch
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISER_REQUEST,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await axiosInstance.post(
        "/register",
        {
          fullName,
          password,
          email,
          phone,
          city,
          state,
          pinCode,
          village,
          fatherName,
          dob,
          gender,
          avatar,
          courseId,
          batch
        },
        config
      );
      dispatch({
        type: USER_REGISER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const login = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.post(
      `/login`,
      {
        email,
        password,
      },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem(
      "niotamToken",
      JSON.stringify(getState().userLogin?.userInfo)
    );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    localStorage.removeItem("niotamToken");

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// GET USER PROFILE DETAILS
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/profile`, config);

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE USER PROFILE
export const updateUserProfile =
  (
    fullName,
    password,
    email,
    phone,
    city,
    state,
    pinCode,
    village,
    fatherName,
    dob,
    gender,
    avatar
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_UPDATE_REQUEST,
      });
      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/update-profile`,
        {
          fullName,
          password,
          email,
          phone,
          city,
          state,
          pinCode,
          village,
          fatherName,
          dob,
          gender,
          avatar,
        },
        config
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE ACCOUNT PASSWORD
export const updatePasswordAction =
  (password, newPassword) => async (dispatch) => {
    try {
      dispatch({
        type: USER_PASSWORD_UPDATE_REQUEST,
      });
      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };
      const { data } = await axiosInstance.put(
        `/update-password`,
        {
          password,
          newPassword,
        },
        config
      );
      dispatch({
        type: USER_PASSWORD_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_PASSWORD_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE USER PROFILE PICTURE
export const updateAvatar = (avatar) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_AVATAR_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(`profile-pic`, { avatar }, config);

    dispatch({
      type: UPDATE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_AVATAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// REMOVE USER PROFILE PICTURE
export const removeAvatar = () => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_AVATAR_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`remove-pic`, config);

    dispatch({
      type: REMOVE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_AVATAR_FAIL,
      payload: error.response.data.message,
    });
  }
};



// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: USER_CLEAR_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: USER_CLEAR_STATE });
};

// CLEAR STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};

// CLEAR STATE ACTION FUNCTION
export const clearPasswordUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_PASSWORD_UPDATE_STATE });
};
