import React, { Fragment } from "react";
import Layout from "../../components/layouts/Layout";
import "../../styles/courses.scss";
import Breadcrumb from "../../components/layouts/Breadcrumb";
import CourseList from "../../components/course/CourseList";

const Courses = () => {

  // useEffect(() => {
    
  // }, [input])

  return (
    <Fragment>
      <Layout>
        <Breadcrumb title={"Courses"} />
        <section className="course_wrap courses">
          <div className="container">
            <div className="header">
              <p className="heading_top">Our Course Categories</p>
              <h2 className="main_heading">
                We Provide <span>expert coaching</span>
              </h2>
            </div>
            <CourseList />
          </div>
        </section>
      </Layout>
    </Fragment>
  );
};

export default Courses;
