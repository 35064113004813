export const indianStates = [
    { id: 1, value: 'andhraPradesh', option: 'ANDHRA PRADESH' },
    { id: 2, value: 'arunachalPradesh', option: 'ARUNACHAL PRADESH' },
    { id: 3, value: 'assam', option: 'ASSAM' },
    { id: 4, value: 'bihar', option: 'BIHAR' },
    { id: 5, value: 'chhattisgarh', option: 'CHHATTISGARH' },
    { id: 6, value: 'goa', option: 'GOA' },
    { id: 7, value: 'gujarat', option: 'GUJARAT' },
    { id: 8, value: 'haryana', option: 'HARYANA' },
    { id: 9, value: 'himachalPradesh', option: 'HIMACHAL PRADESH' },
    { id: 10, value: 'jammuAndKashmir', option: 'JAMMU AND KASHMIR' },
    { id: 11, value: 'jharkhand', option: 'JHARKHAND' },
    { id: 12, value: 'karnataka', option: 'KARNATAKA' },
    { id: 13, value: 'kerala', option: 'KERALA' },
    { id: 14, value: 'madhyaPradesh', option: 'MADHYA PRADESH' },
    { id: 15, value: 'maharashtra', option: 'MAHARASHTRA' },
    { id: 16, value: 'manipur', option: 'MANIPUR' },
    { id: 17, value: 'meghalaya', option: 'MEGHALAYA' },
    { id: 18, value: 'mizoram', option: 'MIZORAM' },
    { id: 19, value: 'nagaland', option: 'NAGALAND' },
    { id: 20, value: 'odisha', option: 'ODISHA' },
    { id: 21, value: 'punjab', option: 'PUNJAB' },
    { id: 22, value: 'rajasthan', option: 'RAJASTHAN' },
    { id: 23, value: 'sikkim', option: 'SIKKIM' },
    { id: 24, value: 'tamilNadu', option: 'TAMIL NADU' },
    { id: 25, value: 'telangana', option: 'TELANGANA' },
    { id: 26, value: 'tripura', option: 'TRIPURA' },
    { id: 27, value: 'uttarPradesh', option: 'UTTAR PRADESH' },
    { id: 28, value: 'uttarakhand', option: 'UTTARAKHAND' },
    { id: 29, value: 'westBengal', option: 'WEST BENGAL' },
    { id: 30, value: 'andamanAndNicobarIslands', option: 'ANDAMAN AND NICOBAR ISLANDS' },
    { id: 31, value: 'chandigarh', option: 'CHANDIGARH' },
    { id: 32, value: 'dadraAndNagarHaveliAndDamanAndDiu', option: 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU' },
    { id: 33, value: 'lakshadweep', option: 'LAKSHADWEEP' },
    { id: 34, value: 'delhi', option: 'DELHI' },
    { id: 35, value: 'puducherry', option: 'PUDUCHERRY' },
  ];
  