import React, { Fragment, useEffect } from "react";
import "../../styles/courseDetails.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
// import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import {
  BsAlarm,
  BsCheckLg,
  BsDownload,
  BsExclamationCircle,
  BsFillPhoneFill,
  BsFillTrophyFill,
  BsGlobe,
  // BsPeopleFill,
  BsStarFill,
  // BsSuitHeart,
  BsYoutube,
} from "react-icons/bs";
import { RiVidiconLine } from "react-icons/ri";
import { GiTeacher } from "react-icons/gi";
import { useAuth } from "../../context/userContext";
// import { addItemsToCart } from "../../redux/action/cartAction";
import { useNotification } from "../../context/notificationContext";
// import {
//   clearPaymentErrors,
//   clearPaymentState,
// } from "../../redux/action/instMojoAction";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/layouts/Layout";
// import ManualButton from "../../components/reusable/ManualButton";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { video_icon } from "../../assest/images";
import {
  clearCourseErrors,
  getCourseDetails,
} from "../../redux/action/courseAction";
import moment from "moment";
import {
  clearPurchaseErrors,
  clearPurchaseState,
  createPurchase,
} from "../../redux/action/purchaseAction";

const CourseDetail = () => {
  const dispatch = useDispatch();
  const [auth] = useAuth();
  const navigate = useNavigate();

  // GET COURSE DETAILS BY ID START
  // GET COURSE DETAILS BY ID START
  const { id: courseId } = useParams();
  const [, , showNotification] = useNotification();

  // GET COURSE DETAILS STATE FROM STORE
  const { loading, courseDetails, error } = useSelector(
    (state) => state.getCourseDetail
  );
  const getCourseById = courseDetails?.course;

  // GET COURSE DETAIL FROM COURSE DETAIL ACTION
  useEffect(() => {
    dispatch(getCourseDetails(courseId));
  }, [courseId, dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearCourseErrors());
    }
  }, [error, dispatch, showNotification]);
  // GET COURSE DETAILS BY ID ENDS
  // GET COURSE DETAILS BY ID ENDS

  // COURSE PURCHASE START
  // COURSE PURCHASE START
  const {
    loading: purchaseDataLoading,
    error: purchaseDataError,
    purchaseData,
  } = useSelector((state) => state.newPurchase);

  let measureCoursePayment = getCourseById?.price;
  if (getCourseById?.discountedPrice) {
    measureCoursePayment = getCourseById?.discountedPrice;
  } else {
    measureCoursePayment = getCourseById?.price;
  }

  const paymentHandler = () => {
    if (!auth?.user?._id) {
      navigate("/login", { state: `/course-detail/${courseId}` });
      showNotification("warning", "Please login before making a payment!");
    } else if (courseId) {
      dispatch(createPurchase(measureCoursePayment, courseId));
    }
  };

  useEffect(() => {
    if (purchaseDataError) {
      dispatch(clearPurchaseErrors());
      showNotification("error", purchaseDataError);
    }

    if (purchaseData?.success) {
      window.location.href = purchaseData?.payment_request?.longurl;
      dispatch(clearPurchaseState());
    }

    if (!purchaseData?.success) {
      if (purchaseData?.message?.phone) {
        showNotification("error", purchaseData?.message?.phone[0]);
      }
      if (purchaseData?.message?.amount) {
        showNotification("error", purchaseData?.message?.amount[0]);
      }
      dispatch(clearPurchaseState());
    }
  }, [
    purchaseDataError,
    showNotification,
    dispatch,
    purchaseData?.success,
    purchaseData?.payment_request?.longurl,
    purchaseData?.message?.phone,
    purchaseData?.message?.amount,
  ]);
  // COURSE PURCHASE ENDS
  // COURSE PURCHASE ENDS

  console.log("getCourseById", getCourseById?.price);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <section className="course_wrapper">
            <div className="container">
              <div className="all_details">
                <nav className="bredcrumb_grid">
                  <ol>
                    <li>
                      <Link to="/">Home</Link>/
                    </li>
                    <li className="active">Course Detail</li>
                  </ol>
                </nav>
                <div className="course_details">
                  <div className="right_section">
                    <div className="headig_txt">
                      <div className="course_detail_img">
                        <img
                          className="main_hero_img"
                          src={getCourseById?.thumbnail?.url}
                          alt="cyberPartnerHero"
                        />
                        <img
                          className="video_icon"
                          src={video_icon}
                          alt="videoIcon"
                        />
                      </div>

                      <h2>{getCourseById?.title}</h2>
                      <p className="sub_heading">
                        {getCourseById?.description}
                      </p>
                      <div className="ratings">
                        <div className="stars">
                          <p>4.6</p>
                          <BsStarFill />
                          <BsStarFill />
                          <BsStarFill />
                          <BsStarFill />
                          <BsStarFill />
                          {/* <Link to="/">( 120 ratings )</Link> */}
                        </div>
                        <div className="count">
                          <p>1,112 students</p>
                        </div>
                      </div>
                      <div className="updates">
                        <ul>
                          <li>
                            <BsExclamationCircle />
                            <p>
                              Last Updated{" "}
                              {moment(getCourseById?.updatedAt)
                                .utcOffset("+05:30")
                                .format("DD/MMM/YYYY")}
                            </p>
                          </li>
                          <li>
                            <BsGlobe />
                            <p>English</p>
                          </li>
                          <li>
                            {/* {(getCourseById?.study_mode === "Online" && (
                                <FaChalkboardTeacher />
                              )) ||
                                (getCourseById?.study_mode === "Offline" && ( */}
                            <GiTeacher />
                            {/* )) ||
                                (getCourseById?.study_mode === "Hybrid" && (
                                  <img src={hybrid_classes} alt="" />
                                ))} */}
                            <p>Offline</p>
                          </li>
                          <li>
                            <RiVidiconLine />
                            <p>Recorded</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="left_section_mob">
                      <div className="details">
                        <div className="price_text">
                          <ul className="prize">
                            {getCourseById?.discountedPrice !== 0 && (
                              <li>
                                <h4>Rs. {getCourseById?.discountedPrice} /-</h4>
                              </li>
                            )}
                            {getCourseById?.discountedPrice !== 0 ? (
                              <>
                                <li>
                                  <strike>Rs {getCourseById?.price}</strike>
                                </li>
                              </>
                            ) : (
                              <li>
                                <h4>Rs. {getCourseById?.price} /-</h4>
                              </li>
                            )}
                          </ul>
                          <span>
                            <BsAlarm />
                            <p>
                              <strong>2 days</strong> left at this price
                            </p>
                          </span>
                          {/* <button className="add_cart">Add To Cart</button> */}
                          <button
                            className="buy_now"
                            disabled={purchaseDataLoading ? true : false}
                            onClick={() => paymentHandler()}
                          >
                            {purchaseDataLoading ? <ButtonLoader /> : "Buy Now"}
                          </button>

                          <ul className="feature">
                            <h4>This course includes:</h4>
                            <li>
                              <BsYoutube />
                              <p>1 hour on-demand video</p>
                            </li>
                            <li>
                              <BsDownload />
                              <p>2 downloadable resources</p>
                            </li>
                            <li>
                              <BsFillPhoneFill />
                              <p>Access on mobile and TV</p>
                            </li>
                            <li>
                              <BsFillTrophyFill />
                              <p>Certificate of completion</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="what_to_learn">
                      <h4>What To Learn</h4>
                      <div className="points">
                        <ul>
                          {getCourseById?.features?.map((item, idx) => (
                            <li key={idx}>
                              <BsCheckLg />
                              <p>{item}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="course_description">
                      <h4>About Course</h4>
                      <div className="content">
                        <p>
                          <strong>
                            **UPDATED FOR 2023 – ALL NEW CONTENT, EXAMPLES AND
                            EXERCISES**
                          </strong>
                        </p>
                        <p>
                          <strong>
                            “I purchased this course way back in 2012 when I was
                            just beginning to take writing seriously… I have
                            looked and re-looked at this course over time and
                            have still found it to be highly relevant and
                            helpful.”
                          </strong>
                          – Caleb K, Udemy student
                        </p>

                        <p>
                          <strong>
                            “Comprehensive guide on keeping it simple. Fewer
                            words for a more compelling story!”
                          </strong>{" "}
                          –Jack O, Udemy student
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          <strong>
                            “Interesting course and easy to follow. I'm already
                            cutting down on wordiness and achieving better
                            results. Good examples and cheat sheets.”
                          </strong>
                          <em> </em>– Ann P, Udemy student
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          Complete this business writing course in{" "}
                          <strong>just 1 hour</strong>, and continue to use the
                          material as a reference.
                        </p>
                        <p>
                          <strong>
                            Enroll now to reduce wordiness and produce more
                            professional writing.
                          </strong>
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          "This course made me aware how many words I frequently
                          use and how many I could lose to make the sentence
                          more crisp. Very helpful!" Franziska B
                        </p>
                        <p>
                          "This was a great course with challenging exercises to
                          refresh or increase your writing skills." Stephen
                        </p>
                        <p>
                          "It was a good match! Is coaching available? Thanks
                          for a helpful and interesting course." Gwendolyn W
                        </p>
                        <p>
                          "Very useful tips. I love the cheat sheets! The
                          instructor's pace was very good." Theresa B. K
                        </p>
                        <p>"Useful tips to write with less words." Octavio M</p>
                        <p>
                          "I undertook this writing course to help my writing
                          become more concise. It achieved that and more – and
                          in very little time. I was surprised at how quickly my
                          writing improved. The examples and exercises are
                          relevant and easy to follow. I will continue to refer
                          to the material in my current role." Jayne K
                        </p>
                        <p>
                          "* Material/delivery clear and concise. * Enjoyed the
                          exercises. would like to see more. * Instructor very
                          knowledgeable." Jerome C
                        </p>
                        <p>
                          "I used the various tools and means to check my
                          writing and improve the way I communicate. Especially
                          when writing emails, I use what I learned to check and
                          make sure it is following the best practice taught."
                          Dawn C
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="left_section"
                    //  ref={leftSectionRef}
                  >
                    <div className="top_img">
                      <img
                        className="main_hero_img"
                        src={getCourseById?.thumbnail?.url}
                        alt="cyberPartnerHero"
                      />
                      <img
                        className="video_icon"
                        src={video_icon}
                        alt="videoIcon"
                      />
                    </div>
                    <div className="details">
                      <div className="price_text">
                        <ul className="prize">
                          {getCourseById?.discountedPrice && (
                            <li>
                              <h4>Rs. {getCourseById?.discountedPrice} /-</h4>
                            </li>
                          )}

                          {getCourseById?.discountedPrice ? (
                            <>
                              <li>
                                <strike>Rs {getCourseById?.price}</strike>
                              </li>
                            </>
                          ) : (
                            <li>
                              <h4>Rs. {getCourseById?.price} /-</h4>
                            </li>
                          )}
                        </ul>
                        <span>
                          <BsAlarm />
                          <p>
                            <strong>2 days</strong> left at this price
                          </p>
                        </span>
                        {/* <button className="add_cart">Add To Cart</button> */}

                        <button
                          className="buy_now"
                          disabled={purchaseDataLoading ? true : false}
                          onClick={() => paymentHandler()}
                        >
                          {purchaseDataLoading ? "Loading..." : "Buy Now"}
                        </button>

                        <ul className="feature">
                          <h4>This course includes:</h4>
                          <li>
                            <BsYoutube />
                            <p>1 hour on-demand video</p>
                          </li>
                          <li>
                            <BsDownload />
                            <p>2 downloadable resources</p>
                          </li>
                          <li>
                            <BsFillPhoneFill />
                            <p>Access on mobile and TV</p>
                          </li>
                          <li>
                            <BsFillTrophyFill />
                            <p>Certificate of completion</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <EnrollNowPopUp /> */}
        </Layout>
      )}
    </Fragment>
  );
};

export default CourseDetail;
