export const FRANCHISE_STUDENT_REGISER_REQUEST = "FRANCHISE_STUDENT_REGISER_REQUEST";
export const FRANCHISE_STUDENT_REGISER_SUCCESS = "FRANCHISE_STUDENT_REGISER_SUCCESS";
export const FRANCHISE_STUDENT_REGISER_FAIL = "FRANCHISE_STUDENT_REGISER_FAIL";

export const GET_ALL_FRANCHISE_STUDENTS_REQUEST = "GET_ALL_FRANCHISE_STUDENTS_REQUEST";
export const GET_ALL_FRANCHISE_STUDENTS_SUCCESS = "GET_ALL_FRANCHISE_STUDENTS_SUCCESS";
export const GET_ALL_FRANCHISE_STUDENTS_FAIL = "GET_ALL_FRANCHISE_STUDENTS_FAIL";

export const GET_FRANCHISE_STUDENT_REQUEST = "GET_FRANCHISE_STUDENT_REQUEST";
export const GET_FRANCHISE_STUDENT_SUCCESS = "GET_FRANCHISE_STUDENT_SUCCESS";
export const GET_FRANCHISE_STUDENT_FAIL = "GET_FRANCHISE_STUDENT_FAIL";

export const DOWNLOAD_STUDENT_INVOICE_REQUEST = "DOWNLOAD_STUDENT_INVOICE_REQUEST";
export const DOWNLOAD_STUDENT_INVOICE_SUCCESS = "DOWNLOAD_STUDENT_INVOICE_SUCCESS";
export const DOWNLOAD_STUDENT_INVOICE_FAIL = "DOWNLOAD_STUDENT_INVOICE_FAIL";

export const DELETE_FRANCHISE_STUDENT_REQUEST = "DELETE_FRANCHISE_STUDENT_REQUEST";
export const DELETE_FRANCHISE_STUDENT_SUCCESS = "DELETE_FRANCHISE_STUDENT_SUCCESS";
export const DELETE_FRANCHISE_STUDENT_FAIL = "DELETE_FRANCHISE_STUDENT_FAIL";

export const UPDATE_FRANCHISE_STUDENT_REQUEST = "UPDATE_FRANCHISE_STUDENT_REQUEST";
export const UPDATE_FRANCHISE_STUDENT_SUCCESS = "UPDATE_FRANCHISE_STUDENT_SUCCESS";
export const UPDATE_FRANCHISE_STUDENT_FAIL = "UPDATE_FRANCHISE_STUDENT_FAIL";

export const FRANCHISE_STUDENT_CLEAR_ERROR = "FRANCHISE_STUDENT_CLEAR_ERROR";
export const FRANCHISE_STUDENT_CLEAR_STATE = "FRANCHISE_STUDENT_CLEAR_STATE";
