import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Card = styled.div`
  position: relative;
  padding-bottom: 20px;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 767px) {
    margin-left: 0px;
    margin-right: 0px;
  }
  > .thumbNail {
    position: relative;
    overflow: hidden;
    background-color:#02298A;
    > img {
      transform: translateY(0) scale(1.0);
      transition: all 1s ease-in;
      width: 100%;
      height:260px;
      object-fit:contain;

    }
  }

  &:hover {
    > .thumbNail {
      > img {
        transform: translateY(-60px) scale(1.0);
      }
    }
    > .featured_details_wrap {
      opacity: 0;
      transform: translateY(100%);
      transition: all 0.5s ease;
    }
    > .services_details_wrap {
      .services_details_box {
        transform: translateY(-91%);
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
  }
  > .featured_details_wrap {
    > .featured_content {
      position: relative;
      padding: 20px 20px 7px;
      margin: -35px 40px 14px;
      z-index: 2;
      text-align: center;
      background-color: #f2f2f2;
      border: 1px solid #dfdfdf;
      @media (max-width: 767px) {
        padding: 10px 10px 0px;
        margin-bottom: 7px;
      }

      h3 {
        text-overflow: ellipsis;
        font-family: poppins;
        color: #003a66;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 15px;
        font-weight: 600;
        text-transform: capitalize;
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 8px;
        }
      }
    }

    > .featured_explore_more {
      text-align: center;
      > a {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
        color: #e02454;
        font-family: poppins;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }

  > .services_details_wrap {
    .services_details_box {
      transform: translateY(-80%);
      opacity: 0;
      transition: all 0.5s ease;
      // margin: 0 40px;
      width:100%;
      position: absolute;

      .services-content {
        background-color: #003a66;
        border: none;
        position: static;
        padding: 0;
        text-align: center;
        margin: 0;
        padding-top: 27px;
        @media (max-width: 767px) {
          padding-top: 15px;
        }

        .services-title {
          h3 {
            text-overflow: ellipsis;
            font-family: poppins;
            color: #f2f2f2;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 15px;
            font-weight: 600;
            text-transform: capitalize;
            @media (max-width: 767px) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 10px;
            }
          }
        }
        .services-desc {
          border-top: 1px solid rgba(255, 255, 255, 0.11);
          padding: 25px 25px 12px 25px;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          color: #fff;
          font-family: nunito;
          overflow: hidden;
          @media (max-width: 767px) {
            padding-top: 12px;
            font-size:14px;
          }
        }
      }
      > .services-explore-more {
        > button {
          padding: 16px 15px 20px;
          display: block;
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          font-family: poppins;
          text-transform: capitalize;
          color: #f2f2f2;
          background-color: #e02454;
          border: none;
          width: 100%;
          cursor: pointer;
          @media (max-width: 767px) {
           font-size:14px;
           padding: 11px 0px 11px;
          }
        }
      }
    }
  }
`;

const CourseCard = ({ data }) => {
  const navigate = useNavigate()

  return (
    <Card>
      <div className="thumbNail">
        <img src={data.thumbnail?.url} alt={data?.title} />
      </div>
      <div className="featured_details_wrap">
        <div className="featured_content">
          <div className="featured-title">
            <h3>{data?.title}</h3>
          </div>
        </div>
        <div className="featured_explore_more">
          <Link to={`/course-detail/${data?._id}`}>explore more</Link>
        </div>
      </div>

      <div className="services_details_wrap">
        <div className="services_details_box">
          <div className="services-content">
            <div className="services-title">
              <h3>{data?.title}</h3>
            </div>
            <div className="services-desc">
              <p>{data?.description?.slice(0,40)}</p>
            </div>
          </div>
          <div className="services-explore-more">
            <button onClick={() => navigate(`/course-detail/${data?._id}`)}>
              explore more
            </button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CourseCard;
