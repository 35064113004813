import React from "react";
import Layout from "../../components/layouts/Layout";

const PaymentPolicy = () => {
  return (
    <Layout>
      <div className="policy_wrapper">
        <div className="container">
          <div className="policy_content">
            <div className="policy_text">
              <h2 className="main_heading txt_center txt_color">
                Payment Policy
              </h2>
              <p>
                We offer various payment methods to accommodate our diverse
                student base. These methods may include credit/debit cards, bank
                transfers, online payment gateways, and other secure channels.
                Students can choose the method that best suits their preferences
                and convenience.
              </p>
              <p>
                Our tuition fees are structured based on the courses and
                services availed by the students. These fees cover the cost of
                educational materials, instructor guidance, access to learning
                resources, and administrative expenses. Additionally, there may
                be charges for supplementary services such as certification
                exams, additional study materials, or specialized tutoring
                sessions.
              </p>
              <p>
                Our payment policies are subject to periodic review and updates
                to align with evolving industry standards and regulatory
                requirements. Any changes to our payment policies are
                communicated to students in advance to ensure transparency and
                awareness.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentPolicy;
