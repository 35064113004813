export const CREATE_FEANCHISE_REQUEST = "CREATE_FEANCHISE_REQUEST";
export const CREATE_FEANCHISE_SUCCESS = "CREATE_FEANCHISE_SUCCESS";
export const CREATE_FEANCHISE_FAIL = "CREATE_FEANCHISE_FAIL";

export const USER_QUERY_REQUEST = "USER_QUERY_REQUEST";
export const USER_QUERY_SUCCESS = "USER_QUERY_SUCCESS";
export const USER_QUERY_FAIL = "USER_QUERY_FAIL";


export const QUERY_CLEAR_ERRORS = "QUERY_CLEAR_ERRORS";
export const QUERY_CLEAR_STATE = "QUERY_CLEAR_STATE";

