import { axiosInstance } from "../../utils/axiosInstance";
import {
  ALL_BATCH_FAIL,
  ALL_BATCH_REQUEST,
  ALL_BATCH_SUCCESS,
  BATCH_DETAIL_FAIL,
  BATCH_DETAIL_REQUEST,
  BATCH_DETAIL_SUCCESS,
  CLEAR_BATCH_DELETE_STATE,
  CLEAR_BATCH_ERRORS,
  CLEAR_BATCH_STATE,
  CREATE_BATCH_FAIL,
  CREATE_BATCH_REQUEST,
  CREATE_BATCH_SUCCESS,
  DELETE_BATCH_FAIL,
  DELETE_BATCH_REQUEST,
  DELETE_BATCH_SUCCESS,
  UPDATE_BATCH_FAIL,
  UPDATE_BATCH_REQUEST,
  UPDATE_BATCH_SUCCESS,
} from "../constant/batchConstant";

export const createBatch = (title, time, studentLimit) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_BATCH_REQUEST,
    });
    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    // let stringFeatures = [];

    // if (subjects) {
    //   stringFeatures = subject;
    // }

    const { data } = await axiosInstance.post(
      "/create-batch",
      {
        title, time, studentLimit
      },
      config
    );
    dispatch({
      type: CREATE_BATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_BATCH_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE COURSE DETAILS
export const updateBatch = (id,title, time, studentLimit) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_BATCH_REQUEST,
    });
    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/batch/${id}`,
      {
        title, time, studentLimit
      },
      config
    );
    dispatch({
      type: UPDATE_BATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BATCH_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET ALL COURSES
export const getAllBatchist = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_BATCH_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/self-created-batches", config);

    dispatch({
      type: ALL_BATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_BATCH_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET COURSE DETAIL BY ID
export const getBatchDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BATCH_DETAIL_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/batch/${id}`, config);

    dispatch({
      type: BATCH_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BATCH_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE COURSE --- ADMIN
export const deleteBatch = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_BATCH_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/category/${id}`, config);

    dispatch({
      type: DELETE_BATCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BATCH_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const batchClearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_BATCH_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const batchClearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_BATCH_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearBatchDeleteState = () => async (dispatch) => {
  dispatch({ type: CLEAR_BATCH_DELETE_STATE });
};
