import { axiosInstance } from "../../utils/axiosInstance";
import {
  CLEAR_PURCHASE_ERROR,
  CLEAR_PURCHASE_STATE,
  PURCHASE_DONE_FAIL,
  PURCHASE_DONE_REQUEST,
  PURCHASE_DONE_SUCCESS,
  PURCHASE_FAIL,
  PURCHASE_LIST_FAIL,
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
} from "../constant/purchaseConstant";

// CREATE NEW PURCHASE
export const createPurchase = (amount, courseId) => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_REQUEST,
    });
    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };
    const { data } = await axiosInstance.post(
      `/purchase`,
      {
        amount,
        courseId,
      },
      config
    );
    dispatch({
      type: PURCHASE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// PURCHASE DONE REQUEST
export const purchaseDoneReq =
  (payment_request_id, payment_status, payment_id, course) =>
  async (dispatch) => {
    try {
      dispatch({
        type: PURCHASE_DONE_REQUEST,
      });
      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };
      const { data } = await axiosInstance.post(
        `/purchase-done`,
        {
          payment_request_id,
          payment_status,
          payment_id,
          course,
        },
        config
      );
      dispatch({
        type: PURCHASE_DONE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PURCHASE_DONE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET PURCHASE LIST REQUEST
export const getPurchaseList = () => async (dispatch) => {
  try {
    dispatch({
      type: PURCHASE_LIST_REQUEST,
    });
    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };
    const { data } = await axiosInstance.get(`/purchase-list`, config);
    dispatch({
      type: PURCHASE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PURCHASE_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearPurchaseErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_PURCHASE_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearPurchaseState = () => async (dispatch) => {
  dispatch({ type: CLEAR_PURCHASE_STATE });
};
