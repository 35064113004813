import { axiosInstance } from "../../utils/axiosInstance";
import {
  DOWNLOAD_STUDENT_INVOICE_FAIL,
  DOWNLOAD_STUDENT_INVOICE_REQUEST,
  DOWNLOAD_STUDENT_INVOICE_SUCCESS,
  FRANCHISE_STUDENT_CLEAR_ERROR,
  FRANCHISE_STUDENT_CLEAR_STATE,
  FRANCHISE_STUDENT_REGISER_FAIL,
  FRANCHISE_STUDENT_REGISER_REQUEST,
  FRANCHISE_STUDENT_REGISER_SUCCESS,
  GET_ALL_FRANCHISE_STUDENTS_FAIL,
  GET_ALL_FRANCHISE_STUDENTS_REQUEST,
  GET_ALL_FRANCHISE_STUDENTS_SUCCESS,
  GET_FRANCHISE_STUDENT_FAIL,
  GET_FRANCHISE_STUDENT_REQUEST,
  GET_FRANCHISE_STUDENT_SUCCESS,
  UPDATE_FRANCHISE_STUDENT_FAIL,
  UPDATE_FRANCHISE_STUDENT_REQUEST,
  UPDATE_FRANCHISE_STUDENT_SUCCESS,
} from "../constant/franchiseStudentConstant";

// FOR USER REGISTRATION
// FOR USER REGISTRATION
export const franchiseUserRegisterAction =
  (
    fullName,
    email,
    phone,
    state,
    city,
    village,
    pinCode,
    fatherName,
    dob,
    password,
    avatar,
    gender,
    role,
    courseId,
    batch
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FRANCHISE_STUDENT_REGISER_REQUEST,
      });

      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/admin/register",
        {
          fullName,
          email,
          phone,
          state,
          city,
          village,
          pinCode,
          fatherName,
          dob,
          password,
          avatar,
          gender,
          role,
          courseId,
          batch,
        },
        config
      );
      dispatch({
        type: FRANCHISE_STUDENT_REGISER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FRANCHISE_STUDENT_REGISER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL USERS LIST
export const getAllFranchiseStudentAction =
  (batchName, courseName) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_FRANCHISE_STUDENTS_REQUEST,
      });

      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      let url = "/franchise/users";

      if (batchName && courseName) {
        url += `?batchId=${batchName}&courseId=${courseName}`;
      }
      const { data } = await axiosInstance.get(url, config);

      dispatch({
        type: GET_ALL_FRANCHISE_STUDENTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_FRANCHISE_STUDENTS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET USER DETAILS BY ID
export const getFranchiseStudentById = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FRANCHISE_STUDENT_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/profile`, config);

    dispatch({
      type: GET_FRANCHISE_STUDENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_FRANCHISE_STUDENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DOWNLOAD STUDENT INVOICE
export const downloadStudentInvoiceById =
  (userId, courseId) => async (dispatch) => {
    try {
      dispatch({
        type: DOWNLOAD_STUDENT_INVOICE_REQUEST,
      });

      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.get(
        `/invoice/user/${userId}/course/${courseId}`,
        config
      );

      dispatch({
        type: DOWNLOAD_STUDENT_INVOICE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DOWNLOAD_STUDENT_INVOICE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE USER PROFILE
export const updateFranchiseStudent =
  (
    fullName,
    email,
    phone,
    gender,
    avatar,
    state,
    city,
    village,
    pincode,
    role
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_FRANCHISE_STUDENT_REQUEST,
      });
      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };
      const { data } = await axiosInstance.put(
        `/update-profile`,
        {
          fullName,
          email,
          phone,
          avatar,
          state,
          city,
          village,
          pincode,
          gender,
          role,
        },
        config
      );
      dispatch({
        type: UPDATE_FRANCHISE_STUDENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_FRANCHISE_STUDENT_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// CLEAR ERROR ACTION FUNCTION
export const clearFranchiseStudentErrors = () => async (dispatch) => {
  dispatch({ type: FRANCHISE_STUDENT_CLEAR_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearFranchiseStudentState = () => async (dispatch) => {
  dispatch({ type: FRANCHISE_STUDENT_CLEAR_STATE });
};
