import { ADD_CART_ITEM, REMOVE_CART_ITEM } from "../constant/cartConstant";

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case ADD_CART_ITEM:
      const item = action.payload;
      // Check the product or item exist in cart or not
      const isItemExist = state.cartItems.find((i) => i.id === item.id);

      if (isItemExist) {
        return {
          ...state,
          message: "Course Already Added in cart!",
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
          message: "Course Successfully Added in cart!",
        };
      }

    case REMOVE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((i) => i.id !== action.payload),
      };

    default:
      return state;
  }
};
