import React from "react";
import Layout from "../../components/layouts/Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { banner1, banner2, banner3, testimonial } from "../../assest/images";
import "../../styles/home.scss";
import { BiChevronsRight, BiChevronsLeft } from "react-icons/bi";
import { FaPassport } from "react-icons/fa";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";

import CourseList from "../../components/course/CourseList";
import Marquee from "../../components/reusable/Marquee";
const Home = () => {
  // SLIDER SETTING START
  const CustomPrevArrow = ({ currentSlide, slideCount, ...props }) => {
    const buttonProps = { ...props };

    return (
      <button {...buttonProps} className="slick-arrow slick-prev">
        <BiChevronsLeft />
      </button>
    );
  };

  const CustomNextArrow = ({ currentSlide, slideCount, ...props }) => {
    const buttonProps = { ...props };

    return (
      <button {...buttonProps} className="slick-arrow slick-next">
        <BiChevronsRight />
      </button>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    // centerMode: true,
    // centerPadding: '10%',
  };

  const reviewSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    // centerMode: true,
    // centerPadding: '10%',
  };

  return (
    <Layout>
      {/* MARUQEE HEADINGS */}
      <Marquee />

      {/* BANNER SECTION STARTS */}
      <div className="banner_section">
        <Slider {...settings}>
          <div>
            <img src={banner1} alt="banner_images" />
          </div>
          <div>
            <img src={banner2} alt="banner_images" />
          </div>
          <div>
            <img src={banner3} alt="banner_images" />
          </div>
        </Slider>
      </div>

      {/* COURSE DETAILS START */}
      <section className="course_wrap">
        <div className="container">
          <div className="header">
            <p className="heading_top">Our Course Categories</p>

            <h2 className="main_heading">
              We Provide <span>expert coaching</span>
            </h2>
          </div>
          <CourseList />
        </div>
      </section>

      {/* OUR MISSION */}
      <section className="about_us_wrapper">
        <div className="bg_blur"></div>
        <div className="container">
          <div className="header">
            <p className="heading_top">Our Mission</p>
            <div className="heading_text">
              <h2 className="main_heading">
                We Provide solution for <span> learning problems</span>
              </h2>
            </div>
          </div>
          <div className="about_content">
            <div className="about_content_text">
              <div className="icon_heading">
                <FaPassport />
                <h4>Our Mission</h4>
              </div>
              <div className="text_content">
                <p>
                  The leading global provider of immigration and visa services
                  with the required reach, agility and client commitment to
                  enable corporations and individuals to more easily navigate
                  complex regulations.
                </p>
              </div>
            </div>
            <div className="about_content_text">
              <div className="icon_heading">
                <HiMiniBuildingOffice2 />
                <h4>Our Vision</h4>
              </div>
              <div className="text_content">
                <p>
                  The leading global provider of immigration and visa services
                  with the required reach, agility and client commitment to
                  enable corporations and individuals to more easily navigate
                  complex regulations.
                </p>
              </div>
            </div>
            <div className="about_content_text">
              <div className="icon_heading">
                <FaPassport />
                <h4>Our Strategy</h4>
              </div>
              <div className="text_content">
                <p>
                  The leading global provider of immigration and visa services
                  with the required reach, agility and client commitment to
                  enable corporations and individuals to more easily navigate
                  complex regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* TESTIMONIAL SECTION START */}
      <section className="testimonial_wrapper">
        <div className="container">
          <div className="header">
            <p className="heading_top">Testimonials</p>
            <div className="heading_text">
              <h2 className="main_heading">
                What our <span> student say</span>
              </h2>
            </div>
          </div>

          <div className="testimonial_slider">
            <div className="container">
              <Slider {...reviewSlider}>
                <div>
                  <div className="testimonials">
                    <div className="testimonial-wrapper">
                      <div className="testimonial-info">
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">
                            <img
                              src={testimonial}
                              alt="testimonial-img"
                              width="72"
                              height="72"
                            />
                          </div>
                        </div>
                        <div className="testimonial-caption">
                          <h3>Peter Daniels</h3>
                          <label>Engineer</label>
                          <div className="star-ratings prt-section-wrapper-cell">
                            <div className="star-ratings">
                              <ul className="rating">
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-content">
                        <blockquote className="testimonial-text">
                          Keep up the good work Truvik and keep helping others
                          to make their dreams be fulfilled! Very thankful to
                          the team.....
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="testimonials">
                    <div className="testimonial-wrapper">
                      <div className="testimonial-info">
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">
                            <img
                              src={testimonial}
                              alt="testimonial-img"
                              width="72"
                              height="72"
                            />
                          </div>
                        </div>
                        <div className="testimonial-caption">
                          <h3>Peter Daniels</h3>
                          <label>Engineer</label>
                          <div className="star-ratings prt-section-wrapper-cell">
                            <div className="star-ratings">
                              <ul className="rating">
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-content">
                        <blockquote className="testimonial-text">
                          Keep up the good work Truvik and keep helping others
                          to make their dreams be fulfilled! Very thankful to
                          the team.....
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="testimonials">
                    <div className="testimonial-wrapper">
                      <div className="testimonial-info">
                        <div className="testimonial-avatar">
                          <div className="testimonial-img">
                            <img
                              src={testimonial}
                              alt="testimonial-img"
                              width="72"
                              height="72"
                            />
                          </div>
                        </div>
                        <div className="testimonial-caption">
                          <h3>Peter Daniels</h3>
                          <label>Engineer</label>
                          <div className="star-ratings prt-section-wrapper-cell">
                            <div className="star-ratings">
                              <ul className="rating">
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="active">
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-content">
                        <blockquote className="testimonial-text">
                          Keep up the good work Truvik and keep helping others
                          to make their dreams be fulfilled! Very thankful to
                          the team.....
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
