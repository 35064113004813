import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import "../../styles/verification.scss";
import CustomInput from "../../components/reusable/CustomInput";
// import { MdOutlineEventNote } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import ManualButton from "../../components/reusable/ManualButton";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/reusable/Loader";
import { useNotification } from "../../context/notificationContext";
import {
  clearDownloadErrors,
  clearDownloadState,
  viewCertificate,
} from "../../redux/action/certifcateAction";
import {
  clearCourseErrors,
  getAllCourses,
} from "../../redux/action/courseAction";
import CustomSelect from "../../components/reusable/CustomSelect";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { useNavigate } from "react-router-dom";
// import { html2pdf } from "html2pdf.js";
const VerifyCertificate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  const {
    loading: courseLoading,
    error: courseError,
    courses: data,
  } = useSelector((state) => state.getAllCourses);

  const allCourses = data?.courses;

  let optionArray = [];

  for (let i = 0; i < allCourses?.length; i++) {
    let optionData = {
      value: allCourses[i]?._id,
      option: allCourses[i]?.title,
    };
    optionArray.push(optionData);
  }

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(clearCourseErrors());
    }
  }, [dispatch, courseError, showNotification]);

  // GET ALL COURSES ENDS
  // GET ALL COURSES ENDS

  const { loading, certificateInfo, error } = useSelector(
    (state) => state.findCertificate
  );

  const [formData, setFormData] = useState({
    roll_no: "",
    course_id: "",
  });

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { roll_no, course_id } = formData;

  //   GET CERTIFICATE DETAILS
  const handleSubmit = (e) => {
    e.preventDefault();
    if (roll_no && course_id) {
      dispatch(viewCertificate(roll_no, course_id));
    } else {
      showNotification("error", "Please fill all the required fields.");
    }
  };


  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearDownloadErrors());
    }

    if (typeof certificateInfo == "string") {
      dispatch(clearDownloadState())
      navigate("/view-result/certificate", {state: certificateInfo});
    }
  }, [dispatch, showNotification, error, roll_no, course_id, certificateInfo, navigate]);
  return (
    <Layout>
      {courseLoading ? (
        <Loader />
      ) : (
        <>
          <div className="verify_form_wrapper">
            <div className="container">
              <div className="search_form">
                <p>Please enter your student Id & course name</p>
                <form>
                  <CustomInput
                    className="verify_input"
                    label={"Student Id"}
                    placeholder={"Your XYZ**2023"}
                    type="text"
                    onChange={handleInputChange}
                    value={formData.roll_no}
                    name="roll_no"
                    icon={<FaUserAlt />}
                  />
                  <CustomSelect
                    className="verify_input"
                    label="Select Course"
                    name="course_id"
                    onChange={handleInputChange}
                    optionArray={optionArray}
                    impStar="*"
                    value={course_id}
                    readOnly={false}
                    placeholder="Please select a course"
                  />
                  <ManualButton
                    className={`srch_btn ${loading ? "disabled" : ""}`}
                    title={loading ? <ButtonLoader /> : "View Certificate"}
                    onClick={handleSubmit}
                    disabled={loading ? true : false}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="verify_policy_wrapper">
            <div className="container">
              <div className="policy_content">
                <div className="policy_text">
                  <h2 className="main_heading txt_center txt_color">
                    Terms Of Download Certifcate
                  </h2>
                  <p>
                    To access certain features of the website, you may be
                    required to register for an account. You agree to provide
                    accurate and current information during the registration
                    process and to update such information to keep it accurate
                    and current.
                  </p>
                  <p>
                    All content on the website, including but not limited to
                    text, graphics, logos, images, audio clips, digital
                    downloads, and data compilations, is the property of the
                    coaching classes website and is protected by copyright laws.
                  </p>
                  <p>
                    All content on the website, including but not limited to
                    text, graphics, logos, images, audio clips, digital
                    downloads, and data compilations, is the property of the
                    coaching classes website and is protected by copyright laws.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default VerifyCertificate;
