import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import { TbError404 } from 'react-icons/tb';
const ErrorPageWrapper = styled.div`
display:flex;
justify-content:center;
align-items:center;
min-height:100vh;
background-color:#02298A;
font-family:poppins;

>.error_wrapper{
    min-width:400px;
    text-align:center;
    background-color:#02298A;
    padding:20px 20px 35px 20px;
    border-radius:10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border:1px solid #f2f2f2;
    >svg{
        font-size:60px;
        color:#f2f2f2;
        margin-bottom:0px;
    }
    >h2{
        font-size:54px;
        font-weight:bold;
        margin:0px 0px 35px 0px;
        color:#f2f2f2;
    }
    >a{
        background-color:transparent;
        border:1px solid #f2f2f2;
        color:#f2f2f2;
        font-size:14px;
        padding:6px 12px;
        border-radius:10px;
        line-height:2;
        margin-bottom:20px;
    }
}

`
const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
        <div className='error_wrapper'>
        <TbError404 />
            <h2>
                Page Not Found
            </h2>
            <Link to="/">
                Go To Homepage
            </Link>
        </div>
    </ErrorPageWrapper>
  )
}

export default ErrorPage