import React, { useEffect, useState } from "react";
import SignUpLayout from "../../components/layouts/SignUpLayout";
import "../../styles/login.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { FaUserAlt, FaLock, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ManualButton from "../../components/reusable/ManualButton";
import { desktop_logo } from "../../assest/images";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from "../../redux/action/userAction";
import { useAuth } from "../../context/userContext";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/reusable/ButtonLoader";

const Login = () => {
  const [showPassword, setshowPassword] = useState(false); // FOR SHOWING PASSWORD IN INPUT
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();
  const location = useLocation();

  // LOGIN STATE AND PARAMETERS
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // DESTRUCTURE FORM DATA
  const { email, password } = formData;

  // HANDLE INPUT CHANGE
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // SUBMIT LOGIN HANDLER
  const loginHandler = (e) => {
    e.preventDefault();
    if (!email || !password) {
      showNotification("waring", "Please fill email & password!");
    } else {
      dispatch(login(email, password));
    }
  };

  // GET LOGIN DATA FROM STORE
  const { loading, userInfo, error } = useSelector((state) => state.userLogin);

  const redirectUrl = location.state ? location.state : "/user/profile";

  // MANAGE ERRORS AND RESPONSES
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
    if (auth?.user?.role === 11 || auth?.user?.role === 10) {
      navigate(redirectUrl);
    }
    if(auth?.user?.role === 12){
      navigate("/franchise/dashboard")
    }
  }, [
    dispatch,
    error,
    userInfo?.message,
    userInfo?.success,
    navigate,
    showNotification,
    auth?.user?.role,
    redirectUrl,
  ]);

  return (
    <SignUpLayout>
      <div className="form_section">
        <form>
          <div className="logo">
            <img src={desktop_logo} alt="desktop_logo" />
          </div>
          <CustomInput
            placeholder={"Your Email"}
            type={"email"}
            onChange={handleInput}
            value={formData.email}
            name={"email"}
            icon={<FaUserAlt />}
          />
          <CustomInput
            placeholder={"Password"}
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={handleInput}
            value={formData.password}
            icon={<FaLock />}
            eyeIcon={showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
            setshowPassword={setshowPassword}
          />
          <div className="do_not_sign">
            <p>Didn't have any account ?</p>
            <Link to="/register">Sign Up</Link>
          </div>
          <div className="forget_password">
            <Link to="/forget-password">Forget Password ?</Link>
          </div>

          <div className="submit_button">
            <ManualButton
              className={"login_btn"}
              title={loading ? <ButtonLoader /> : "Login"}
              onClick={loginHandler}
              width={"120px"}
            />
          </div>
        </form>
      </div>
    </SignUpLayout>
  );
};

export default Login;
