import React, { Fragment, useEffect, useState } from "react";
import "../../styles/header.scss";
import { desktop_logo, userImg } from "../../assest/images";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTelegram } from "react-icons/fa";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { headerLinks } from "./headerLinks";
import { useAuth } from "../../context/userContext";
import { logout } from "../../redux/action/userAction";
import { useDispatch } from "react-redux";
const Header = () => {
  const navigate = useNavigate();
  // EMAIL AND PHONE  ADDRESS
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const emailAddress = "deepakyad54@gmail.com";
  const phoneNumber = "9467380839";
  const [openToggler, setOpenToggler] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const handleLogin = () => {
    navigate("/login");
  };
  const handleRegister = () => {
    navigate("/register");
  };

  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 90;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <div className="navbar_wrapper">
        <div className="nav_top_sec">
          <div className="container">
            <div className="important_links">
              <div className="email_acc">
                <span>
                  <p>Email to:</p>
                  <a
                    href={`mailto:${emailAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {emailAddress}
                  </a>
                </span>
                <span>
                  <p>Call Us:</p>
                  <a
                    href={`tel:+91 ${phoneNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {phoneNumber}
                  </a>
                </span>
              </div>
              <div className="social_link">
                <ul>
                  <li>
                    <a
                      href={`mailto:${emailAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`mailto:${emailAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href={`mailto:${emailAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTelegram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`nav_bottom ${scrolled ? "scrolled" : ""}`}>
          <div className="container">
            <div className="nav_main_sec">
              <div className="logo">
                <Link to="/">
                  <img
                    className="desktop"
                    src={desktop_logo}
                    alt="Niotam logo"
                  />
                </Link>
              </div>
              <div className="open_navbar_btn">
                <GiHamburgerMenu onClick={() => setOpenToggler(!openToggler)} />
              </div>
              <div className={`nav_links ${openToggler ? "open_navbar" : ""}`}>
                <div className="close_menu_btn">
                  <AiOutlineClose
                    onClick={() => setOpenToggler(!openToggler)}
                  />
                </div>
                {headerLinks.map((ele) => {
                  return (
                    <div className="nav_link" key={ele.id}>
                      <NavLink to={`${ele.link}`}>{ele.name}</NavLink>
                    </div>
                  );
                })}
                <div className="nav_link dropdown_nav">
                  {!auth?.user?._id ? (
                    <div className="headerSign_btns">
                      <button className="sign_up_btn" onClick={handleLogin}>
                        Sign In
                      </button>
                      <button className="sign_up_btn" onClick={handleRegister}>
                        Register
                      </button>
                    </div>
                  ) : (
                    <div className="auth_profile_dropdown">
                      <button
                        className={` ${
                          auth?.user?.fullName ? "profile_header_btn" : ""
                        }`}
                        onClick={() => setProfileDropdown(!profileDropdown)}
                      >
                        <img src={userImg} alt={auth?.user?.fullName} />
                        {auth?.user?.fullName}
                        {profileDropdown ? (
                          <BsFillCaretUpFill />
                        ) : (
                          <BsFillCaretDownFill />
                        )}
                      </button>
                      <div
                        className={`nav_dropdown_links ${
                          profileDropdown ? "show_nav_dropdown" : ""
                        }`}
                      >
                        <Link to={
                         ( auth?.user?.role === 11 || auth?.user?.role === 10) ?
                          "/user/profile" : "/franchise/profile"
                        }>Profile</Link>
                        <button className="logout_btn" onClick={handleLogout}>
                          logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
