import { axiosInstance } from "../../utils/axiosInstance";
import {
  VIEW_CERTIFICATE_FAIL,
  VIEW_CERTIFICATE_REQUEST,
  VIEW_CERTIFICATE_SUCCESS,
  CLEAR_DOWNLOAD_ERROR,
  CLEAR_DOWNLOAD_STATE,
  CREATE_CERTIFICATE_REQUEST,
  CREATE_CERTIFICATE_FAIL,
  CREATE_CERTIFICATE_SUCCESS,
  // EDIT_CERTIFICATE_REQUEST,
  // EDIT_CERTIFICATE_SUCCESS,
  // EDIT_CERTIFICATE_FAIL,
  GET_ALL_CERTIFICATE_REQUEST,
  GET_ALL_CERTIFICATE_SUCCESS,
  GET_ALL_CERTIFICATE_FAIL,
  CLEAR_RESULT_STATE,
} from "../constant/certificateConstant";

// CREATE CERTIFICATE OF USER
export const createCertifcate =
  (rollNo, courseId, grade, startDate, endDate, duration,issueDate) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_CERTIFICATE_REQUEST,
      });
      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };
      const { data } = await axiosInstance.post(
        "/create-result",
        {
          rollNo,
          courseId,
          grade,
          startDate,
          endDate,
          duration,
          issueDate
        },
        config
      );
      dispatch({
        type: CREATE_CERTIFICATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_CERTIFICATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE RESULT DETAILS
// export const updateResult =
//   (id, rollNo, courseId, grade, startDate, endDate, duration) =>
//   async (dispatch) => {
//     try {
//       dispatch({
//         type: EDIT_CERTIFICATE_REQUEST,
//       });
//       const localStorageToken = localStorage.getItem("niotamToken")
//         ? JSON.parse(localStorage.getItem("niotamToken"))
//         : "";

//       const config = {
//         headers: {
//           "content-type": "multipart/form-data",
//           Authorization: `Bearer ${localStorageToken?.token}`,
//         },
//       };

//       const { data } = await axiosInstance.put(
//         `/course/${id}`,
//         {
//           id,
//           rollNo,
//           courseId,
//           grade,
//           startDate,
//           endDate,
//           duration,
//         },
//         config
//       );
//       dispatch({
//         type: EDIT_CERTIFICATE_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: EDIT_CERTIFICATE_FAIL,
//         payload: error.response.data.message,
//       });
//     }
//   };

// GET ALL RESULT LIST
export const getAllResult = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CERTIFICATE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/self-created-results", config);

    dispatch({
      type: GET_ALL_CERTIFICATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CERTIFICATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER CERTIFICATE DETAILS
export const viewCertificate = (roll_no, course_id) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_CERTIFICATE_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/find-result/${roll_no}/${course_id}`,
      config
    );

    dispatch({
      type: VIEW_CERTIFICATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VIEW_CERTIFICATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearDownloadErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_DOWNLOAD_ERROR });
};

export const clearResultState = () => async (dispatch) => {
  dispatch({ type: CLEAR_RESULT_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearDownloadState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DOWNLOAD_STATE });
};
