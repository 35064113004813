import {
  CLEAR_PURCHASE_ERROR,
  CLEAR_PURCHASE_STATE,
  PURCHASE_DONE_FAIL,
  PURCHASE_DONE_REQUEST,
  PURCHASE_DONE_SUCCESS,
  PURCHASE_FAIL,
  PURCHASE_LIST_FAIL,
  PURCHASE_LIST_REQUEST,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_REQUEST,
  PURCHASE_SUCCESS,
} from "../constant/purchaseConstant";

// NEW PURCHASE REDUCER
export const newPurchaseReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_REQUEST:
      return {
        loading: true,
        purchaseData: {},
      };
    case PURCHASE_SUCCESS:
      return {
        loading: false,
        purchaseData: action.payload,
      };
    case PURCHASE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_PURCHASE_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PURCHASE_STATE:
      return {
        loading: false,
        error: null,
        purchaseData: null,
      };

    default:
      return state;
  }
};

// PURCHASE DONE REDUCER
export const purchaseDoneReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_DONE_REQUEST:
      return {
        loading: true,
        purchaseData: {},
      };
    case PURCHASE_DONE_SUCCESS:
      return {
        loading: false,
        purchaseData: action.payload,
      };
    case PURCHASE_DONE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_PURCHASE_ERROR:
      return {
        loading: false,
        error: null,
      };

    case CLEAR_PURCHASE_STATE:
      return {
        loading: false,
        error: null,
        purchaseData: null,
      };

    default:
      return state;
  }
};

// GET PURCHASE LIST REDUCER
export const purchaseListReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_LIST_REQUEST:
      return {
        loading: true,
        purchaseData: {},
      };
    case PURCHASE_LIST_SUCCESS:
      return {
        loading: false,
        purchaseData: action.payload,
      };
    case PURCHASE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_PURCHASE_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
