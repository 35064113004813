import {
    ALL_COURSES_FAIL,
    ALL_COURSES_REQUEST,
    ALL_COURSES_SUCCESS,
    CLEAR_COURSE_ERRORS,
    COURSE_DETAIL_FAIL,
    COURSE_DETAIL_REQUEST,
    COURSE_DETAIL_SUCCESS,
  } from "../constant/courseConstant";
  
  
  // GET ALL COURSES REDUCER
  export const getAllCoursesReducer = (state = {}, action) => {
    switch (action.type) {
      case ALL_COURSES_REQUEST:
        return {
          loading: true,
          courses: {},
        };
      case ALL_COURSES_SUCCESS:
        return {
          loading: false,
          courses: action.payload,
        };
      case ALL_COURSES_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_COURSE_ERRORS:
        return {
          courses: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  // GET COURSE DETAILS REDUCER
  export const getCourseDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case COURSE_DETAIL_REQUEST:
        return {
          loading: true,
          courseDetails: {},
        };
      case COURSE_DETAIL_SUCCESS:
        return {
          loading: false,
          courseDetails: action.payload,
        };
      case COURSE_DETAIL_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_COURSE_ERRORS:
        return {
          courseDetails: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
