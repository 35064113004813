export const CREATE_EXAM_REQUEST = "CREATE_EXAM_REQUEST";
export const CREATE_EXAM_SUCCESS = "CREATE_EXAM_SUCCESS";
export const CREATE_EXAM_FAIL = "CREATE_EXAM_FAIL";

export const UPDATE_EXAM_REQUEST = "UPDATE_EXAM_REQUEST";
export const UPDATE_EXAM_SUCCESS = "UPDATE_EXAM_SUCCESS";
export const UPDATE_EXAM_FAIL = "UPDATE_EXAM_FAIL";

export const ALL_EXAM_REQUEST = "ALL_EXAM_REQUEST";
export const ALL_EXAM_SUCCESS = "ALL_EXAM_SUCCESS";
export const ALL_EXAM_FAIL = "ALL_EXAM_FAIL";

export const PRINT_HALL_TICKET_REQUEST = "PRINT_HALL_TICKET_REQUEST";
export const PRINT_HALL_TICKET_SUCCESS = "PRINT_HALL_TICKET_SUCCESS";
export const PRINT_HALL_TICKET_FAIL = "PRINT_HALL_TICKET_FAIL";

export const EXAM_DETAIL_REQUEST = "EXAM_DETAIL_REQUEST";
export const EXAM_DETAIL_SUCCESS = "EXAM_DETAIL_SUCCESS";
export const EXAM_DETAIL_FAIL = "EXAM_DETAIL_FAIL";

export const DELETE_EXAM_REQUEST = "DELETE_EXAM_REQUEST";
export const DELETE_EXAM_SUCCESS = "DELETE_EXAM_SUCCESS";
export const DELETE_EXAM_FAIL = "DELETE_EXAM_FAIL";
export const CLEAR_EXAM_DELETE_STATE = "CLEAR_EXAM_DELETE_STATE";

export const CLEAR_EXAM_ERRORS = "CLEAR_EXAM_ERRORS";
export const CLEAR_EXAM_STATE = "CLEAR_EXAM_STATE";


