import React, { Fragment, useEffect, useState } from "react";
import NoDataCard from "../../../components/reusable/NoDataCard";
import DetailsPopup from "../../../components/reusable/DetailsPopup";
import moment from "moment";
import FranchiseLayout from "../../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../../components/franchiseLayout/SidebarLayout";
import Loader from "../../../components/reusable/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearWalletErrors,
  getSelfTransactionAction,
} from "../../../redux/action/walletAction";
import { useNotification } from "../../../context/notificationContext";
import { FaPlus, FaRegSadTear } from "react-icons/fa";
import "../../../styles/transactionList.scss";

const TransactionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [, , showNotification] = useNotification();

  const { loading, error, transactions } = useSelector(
    (state) => state.getSelfTransaction
  );

  console.log(transactions);

  useEffect(() => {
    dispatch(getSelfTransactionAction());
  }, [dispatch]);

  //   HANLDE TRANSACTION LIST ERROR
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearWalletErrors());
    }
  }, [error, dispatch, showNotification]);

  const addTransaction = () => {
    navigate("/franchise/recharge-wallet");
  };

  const [transaction, setTransaction] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);

  const handleViewDetails = (details) => {
    setTransaction(!transaction);
    setTransactionDetails(details);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <FranchiseLayout>
          <ProfileLayout>
            <div className="transaction_list">
              <div className="title">
                <h2>Transaction List</h2>
                <button className="create_btn" onClick={() => addTransaction()}>
                  <FaPlus />
                </button>
              </div>
              {transactions?.transactions?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="timing_table customScroll">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Amount</th>
                          <th>Payment Method</th>
                          <th>Transaction Id</th>

                          <th>Created Date & Time</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions?.transactions?.map((ele, idx) => (
                          <tr key={ele._id}>
                            <td>
                              <p>{ele?.amount}</p>
                            </td>
                            <td>{ele?.paidBy}</td>
                            <td>
                              <p>{ele?.txnId}</p>
                            </td>

                            <td>
                              <p>
                                {moment(ele?.createdAt).format(
                                  "MMMM-DD-YYYY [at] h:mm A"
                                )}
                              </p>
                            </td>

                            <td className="txt_center">
                              <span
                                className={`${
                                  ele?.isVerified ? "green" : "red"
                                }`}
                              >
                                {ele?.isVerified ? "Verified" : "Pending"}
                              </span>
                            </td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <NoDataCard
                  txt="There is no transaction yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
          {transaction && (
            <DetailsPopup
              title="Transaction Detail"
              onClick={handleViewDetails}
              details={transactionDetails}
            />
          )}
        </FranchiseLayout>
      )}
    </Fragment>
  );
};

export default TransactionList;
