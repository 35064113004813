import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import Breadcrumb from "../../components/layouts/Breadcrumb";
// import { formBg } from '../../assest/images';
import "../../styles/contactUs.scss";
import CustomInput from "../../components/reusable/CustomInput";
import CustomTextarea from "../../components/reusable/CustomTextArea";
import ManualButton from "../../components/reusable/ManualButton";
import { MdOutlineMail, MdOutlineSmartphone } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import {
  clearQueryErrors,
  clearQueryState,
  createQueryAction,
} from "../../redux/action/queryAction";
import ButtonLoader from "../../components/reusable/ButtonLoader";
const ContactUs = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [, , showNotification] = useNotification();
  const { loading, queryInfo, error } = useSelector(
    (state) => state.queryRequest
  );
  // INPUT ON CHANGE FUNCTION
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const { name, email, phone, message } = formData;

  // QUERY FORM SUBMIT
  const handleSubmit = async () => {
    dispatch(createQueryAction(name, email, phone, message));
  };

  //  HANDLE ERROR AND MESSAGE AFTER SUBMIT QUERY
  useEffect(() => {
    if (queryInfo?.success) {
      showNotification("success", queryInfo?.message);
      dispatch(clearQueryState());
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
    if (error) {
      showNotification("error", error);
      dispatch(clearQueryErrors());
    }
  }, [
    dispatch,
    showNotification,
    error,
    queryInfo?.success,
    queryInfo?.message,
  ]);

  return (
    <Layout>
      <Breadcrumb title={"Contact Us"} />
      <section className="contact_wrapper">
        <div className="container">
          <div className="content_section">
            <div className="form_content">
              <h2 className="main_heading">
                Have be any question? feel free to <span>Contact</span>
              </h2>
              <div className="form">
                <div className="contact_form">
                  <div className="user_details">
                    <CustomInput
                      label={"Full Name"}
                      placeholder={"Your Full Name"}
                      type={"text"}
                      onChange={handleInputChange}
                      value={formData.name}
                      name={"name"}
                      icon={<FaUserAlt />}
                    />
                    <CustomInput
                      label={"Email"}
                      placeholder={"Your Email"}
                      type={"email"}
                      onChange={handleInputChange}
                      value={formData.email}
                      name={"email"}
                      icon={<MdOutlineMail />}
                    />
                    <CustomInput
                      label="Whatsapp No."
                      type="number"
                      placeholder="Your Whatsapp No."
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      icon={<MdOutlineSmartphone />}
                    />
                  </div>
                  <div className="query_details">
                    <CustomTextarea
                      label="Ask Query"
                      type="text"
                      row="12"
                      placeholder="Your query"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="submit_form">
                  <ManualButton
                    title={loading ? <ButtonLoader /> : "Send Query"}
                    onClick={handleSubmit}
                    width={"160px"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactUs;
