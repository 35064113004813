
export const ALL_COURSES_REQUEST = "ALL_COURSES_REQUEST";
export const ALL_COURSES_SUCCESS = "ALL_COURSES_SUCCESS";
export const ALL_COURSES_FAIL = "ALL_COURSES_FAIL";

export const COURSE_DETAIL_REQUEST = "COURSE_DETAIL_REQUEST";
export const COURSE_DETAIL_SUCCESS = "COURSE_DETAIL_SUCCESS";
export const COURSE_DETAIL_FAIL = "COURSE_DETAIL_FAIL";

export const CLEAR_COURSE_ERRORS = "CLEAR_COURSE_ERRORS";
export const CLEAR_COURSE_STATE = "CLEAR_COURSE_STATE";

