export const USER_REGISER_REQUEST = "USER_REGISER_REQUEST";
export const USER_REGISER_SUCCESS = "USER_REGISER_SUCCESS";
export const USER_REGISER_FAIL = "USER_REGISER_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const CLEAR_UPDATE_STATE = "CLEAR_UPDATE_STATE";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const USER_PASSWORD_UPDATE_REQUEST = "USER_PASSWORD_UPDATE_REQUEST";
export const USER_PASSWORD_UPDATE_SUCCESS = "USER_PASSWORD_UPDATE_SUCCESS";
export const USER_PASSWORD_UPDATE_FAIL = "USER_PASSWORD_UPDATE_FAIL";
export const CLEAR_PASSWORD_UPDATE_STATE = "CLEAR_PASSWORD_UPDATE_STATE";

export const UPDATE_PROFILE_PIC_REQUEST = "UPDATE_PROFILE_PIC_REQUEST";
export const UPDATE_PROFILE_PIC_SUCCESS = "UPDATE_PROFILE_PIC_SUCCESS";
export const UPDATE_PROFILE_PIC_FAIL = "UPDATE_PROFILE_PIC_FAIL";

export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_AVATAR_FAIL = "UPDATE_AVATAR_FAIL";

export const REMOVE_AVATAR_REQUEST = "REMOVE_AVATAR_REQUEST";
export const REMOVE_AVATAR_SUCCESS = "REMOVE_AVATAR_SUCCESS";
export const REMOVE_AVATAR_FAIL = "REMOVE_AVATAR_FAIL";


export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const CLEAR_PROFILE_ERROR = "CLEAR_PROFILE_ERROR";
export const USER_CLEAR_ERROR = "USER_CLEAR_ERROR";
export const USER_CLEAR_STATE = "USER_CLEAR_STATE";
