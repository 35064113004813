export const RECHARGE_WALLET_REQUEST = "RECHARGE_WALLET_REQUEST";
export const RECHARGE_WALLET_SUCCESS = "RECHARGE_WALLET_SUCCESS";
export const RECHARGE_WALLET_FAIL = "RECHARGE_WALLET_FAIL";

export const GET_SELF_CREATED_TRANSACTION_REQUEST =
  "GET_SELF_CREATED_TRANSACTION_REQUEST";
export const GET_SELF_CREATED_TRANSACTION_SUCCESS =
  "GET_SELF_CREATED_TRANSACTION_SUCCESS";
export const GET_SELF_CREATED_TRANSACTION_FAIL =
  "GET_SELF_CREATED_TRANSACTION_FAIL";

export const GET_WALLET_REQUEST = "GET_WALLET_REQUEST";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";

export const CLEAR_WALLET_TRANSACTION_ERROR = "CLEAR_WALLET_TRANSACTION_ERROR";
export const CLEAR_WALLET_TRANSACTION_STATE = "CLEAR_WALLET_TRANSACTION_STATE";
