import React, { Fragment, useEffect, useState } from "react";
import "../../styles/franchiseHeader.scss";
import { desktop_logo, userImg } from "../../assest/images";
import { Link, useNavigate } from "react-router-dom";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { useAuth } from "../../context/userContext";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/action/userAction";
const FranchiseHeader = () => {
  const navigate = useNavigate();
  // EMAIL AND PHONE  ADDRESS
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <div className="franchise_navbar_wrapper">
        <div className={`nav_bottom ${scrolled ? "scrolled" : ""}`}>
          <div className="container">
            <div className="nav_main_sec">
              <div className="logo">
                <Link to="/">
                  <img
                    className="desktop"
                    src={desktop_logo}
                    alt="Niotam logo"
                  />
                </Link>
              </div>
              <div className="nav_links">
                <div className="nav_link dropdown_nav">
                  <div className="auth_profile_dropdown">
                    <button
                      className={` ${
                        auth?.user?.fullName ? "profile_header_btn" : ""
                      }`}
                      onClick={() => setProfileDropdown(!profileDropdown)}
                    >
                      <img
                        src={
                          auth?.user?.avatar ? auth?.user?.avatar?.url : userImg
                        }
                        alt={auth?.user?.fullName}
                      />
                      {auth?.user?.fullName}
                      {profileDropdown ? (
                        <BsFillCaretUpFill />
                      ) : (
                        <BsFillCaretDownFill />
                      )}
                    </button>
                    <div
                      className={`nav_dropdown_links ${
                        profileDropdown ? "show_nav_dropdown" : ""
                      }`}
                    >
                      <Link to="/franchise/profile">Profile</Link>
                      <button className="logout_btn" onClick={handleLogout}>
                        logout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FranchiseHeader;
