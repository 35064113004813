import {
  CREATE_FEANCHISE_FAIL,
  CREATE_FEANCHISE_REQUEST,
  CREATE_FEANCHISE_SUCCESS,
  QUERY_CLEAR_ERRORS,
  QUERY_CLEAR_STATE,
  USER_QUERY_FAIL,
  USER_QUERY_REQUEST,
  USER_QUERY_SUCCESS,
} from "../constant/queryConstant";

// FOR USER query
export const queryRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_QUERY_REQUEST:
      return {
        loading: true,
        queryInfo: {},
      };
    case USER_QUERY_SUCCESS:
      return {
        loading: false,
        queryInfo: action.payload,
      };
    case USER_QUERY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case QUERY_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case QUERY_CLEAR_STATE:
      return {
        loading: false,
        queryInfo: {},
        error: null,
      };

    default:
      return state;
  }
};

// FOR USER FRANCHISE REQUEST
export const franchiseRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_FEANCHISE_REQUEST:
      return {
        loading: true,
        queryInfo: {},
      };
    case CREATE_FEANCHISE_SUCCESS:
      return {
        loading: false,
        queryInfo: action.payload,
      };
    case CREATE_FEANCHISE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case QUERY_CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case QUERY_CLEAR_STATE:
      return {
        loading: false,
        queryInfo: {},
        error: null,
      };

    default:
      return state;
  }
};
