import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import { thunk } from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import {
  removeProfilePic,
  updatePasswordReducer,
  updateProfilePicReducer,
  updateProfileReducer,
  userLoginReducer,
  userProfileReducer,
  userRegister,
} from "./reducer/userReducer";
import { cartReducer } from "./reducer/cartReducer";
// import { createPayment } from "./action/instMojoAction";
import {
  franchiseRequestReducer,
  queryRequestReducer,
} from "./reducer/queryReducer";
import {
  findCertificateReducer,
  getAllResultReducer,
  resultCreateReducer,
} from "./reducer/certificateReducer";
import {
  getAllCoursesReducer,
  getCourseDetailsReducer,
} from "./reducer/courseReducer";
import {
  newPurchaseReducer,
  purchaseDoneReducer,
  purchaseListReducer,
} from "./reducer/purchaseReducer";
import {
  downloadStudentInvoiceReducer,
  franchiseStudentRegisterReducer,
  getAllFranchiseStudentReducer,
  getFranchiseStudentDetailsReducer,
  updateFranchiseStudentReducer,
} from "./reducer/franchiseStudentReducer";
import {
  batchCreateReducer,
  batchUpdateReducer,
  deleteBatchReducer,
  getAllBatchReducer,
  getBatchDetailsReducer,
} from "./reducer/batchReducer";
import { kycUpdateReducer } from "./reducer/kycReducer";
import {
  addWalletMoneyReducer,
  getSelfTransactionReducer,
  getWalletReducer,
} from "./reducer/walletReducer";
import {
  examCreateReducer,
  getAllExamReducer,
  printHallTicketReducer,
} from "./reducer/examReducer";
import { findMarksheetReducer, getAllMarksheetReducer, markSheetCreateReducer } from "./reducer/markSheetReducer";

// get user from localStorage
const userFromLocalStorage = localStorage.getItem("niotamToken")
  ? JSON.parse(localStorage.getItem("niotamToken"))
  : {};

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  register: userRegister,
  userLogin: userLoginReducer,
  profile: userProfileReducer,
  updateProfile: updateProfileReducer,
  updatePassword: updatePasswordReducer,
  userAvatar: updateProfilePicReducer,
  removeAvatar: removeProfilePic,
  createResult: resultCreateReducer,
  getAllResult: getAllResultReducer,
  findCertificate: findCertificateReducer,
  cart: cartReducer,
  queryRequest: queryRequestReducer,
  franchiseRequest: franchiseRequestReducer,
  getAllCourses: getAllCoursesReducer,
  getCourseDetail: getCourseDetailsReducer,
  newPurchase: newPurchaseReducer,
  purchaseDone: purchaseDoneReducer,
  purchaseList: purchaseListReducer,

  // FRANCHISE REDUCERS
  kycUpdate: kycUpdateReducer,

  franchiseStudentRegister: franchiseStudentRegisterReducer,
  getAllFranchiseStudent: getAllFranchiseStudentReducer,
  getFranchiseStudentDetails: getFranchiseStudentDetailsReducer,
  updateFranchiseStudent: updateFranchiseStudentReducer,
  downloadStudentInvoice: downloadStudentInvoiceReducer,

  batchCreate: batchCreateReducer,
  batchUpdate: batchUpdateReducer,
  getAllBatch: getAllBatchReducer,
  getBatchDetails: getBatchDetailsReducer,
  deleteBatch: deleteBatchReducer,

  getSelfTransaction: getSelfTransactionReducer,
  addWalletMoney: addWalletMoneyReducer,
  getWallet: getWalletReducer,

  getAllExam: getAllExamReducer,
  examCreate: examCreateReducer,
  printHallTicket: printHallTicketReducer,

  markSheetCreate: markSheetCreateReducer,
  getAllMarksheet: getAllMarksheetReducer,
  findMarksheet: findMarksheetReducer,
});

// INITIAL STATE
const initialState = {
  userLogin: {
    userInfo: userFromLocalStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

// const store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

const store = createStore(
  reducer,
  initialState,
  compose(applyMiddleware(...middleware))
);

export default store;
