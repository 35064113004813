import {
  CLEAR_PROFILE_ERROR,
  CLEAR_UPDATE_STATE,
  USER_CLEAR_ERROR,
  USER_CLEAR_STATE,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_REGISER_FAIL,
  USER_REGISER_REQUEST,
  USER_REGISER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
   REMOVE_AVATAR_FAIL,
  REMOVE_AVATAR_REQUEST,
  REMOVE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAIL,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  USER_PASSWORD_UPDATE_REQUEST,
  USER_PASSWORD_UPDATE_SUCCESS,
  USER_PASSWORD_UPDATE_FAIL,
  CLEAR_PASSWORD_UPDATE_STATE,
} from "../constant/userConstant";

// FOR USER REGISTRATION
export const userRegister = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISER_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case USER_REGISER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_REGISER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case USER_CLEAR_STATE:
      return {
        loading: false,
        userInfo: {},
        error: null,
      };

    default:
      return state;
  }
};

// USER LOGIN REDUCER
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case USER_CLEAR_ERROR:
      return {
        loading: false,
        isAuthenticated: false,
        userInfo: null,
        error: null,
      };
    case USER_CLEAR_STATE:
      return {
        loading: false,
        isAuthenticated: false,
        error: null,
        userInfo: {},
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        userInfo: {},
      };
    case USER_PROFILE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        userInfo: action.payload,
      };
    case USER_PROFILE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: null,
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userInfo: {},
      };

    default:
      return state;
  }
};

// update user profile
export const updateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_UPDATE_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        loading: false,
        userInfo: null,
      };

    default:
      return state;
  }
};

// UPDATE USER PROFILE PICTURE ======
export const updateProfilePicReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AVATAR_REQUEST:
      return {
        loading: true,
        avatarInfo: {},
      };
    case UPDATE_AVATAR_SUCCESS:
      return {
        loading: false,
        avatarInfo: action.payload,
      };
    case UPDATE_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        avatarInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE USER PASSWORD 
export const updatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PASSWORD_UPDATE_REQUEST:
      return {
        loading: true,
        passwordInfo: {},
      };
    case USER_PASSWORD_UPDATE_SUCCESS:
      return {
        loading: false,
        passwordInfo: action.payload,
      };
    case USER_PASSWORD_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_PASSWORD_UPDATE_STATE:
      return {
        passwordInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// REMOVE PROFILE PICTURE
export const removeProfilePic = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_AVATAR_REQUEST:
      return {
        loading: true,
        avatarInfo: {},
      };

    case REMOVE_AVATAR_SUCCESS:
      return {
        loading: false,
        avatarInfo: action.payload,
      };

    case REMOVE_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        avatarInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};


