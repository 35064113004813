import React, { Fragment, useEffect } from "react";
import { FaBookOpen, FaRupeeSign, FaUser } from "react-icons/fa";
import "../../styles/franchiseDashboard.scss";
import ProfileLayout from "../../components/franchiseLayout/SidebarLayout";
import FranchiseLayout from "../../components/franchiseLayout/FranchiseLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  clearWalletErrors,
  getWalletMoney,
} from "../../redux/action/walletAction";
// import { useNotification } from "../../context/notificationContext";
import Loader from "../../components/reusable/Loader";
const FranchiseDashboard = () => {
  const dispatch = useDispatch();
  const { error, loading, walletInfo } = useSelector(
    (state) => state.getWallet
  );
  useEffect(() => {
    dispatch(getWalletMoney());
  }, [dispatch]);
  useEffect(() => {
    if (error) {
      dispatch(clearWalletErrors());
    }
  }, [error, dispatch]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <FranchiseLayout>
          <ProfileLayout>
            <div className="home_wrapper">
              <div className="card">
                <div className="count">
                  <p>12</p>
                  <span>
                    <FaBookOpen />
                  </span>
                </div>
                <div className="title">Courses</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>1200</p>
                  <span>
                    <FaUser />
                  </span>
                </div>
                <div className="title">Users</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>12</p>
                  <span>
                    <FaBookOpen />
                  </span>
                </div>
                <div className="title">Courses</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>1200</p>
                  <span>
                    <FaUser />
                  </span>
                </div>
                <div className="title">Users</div>
              </div>
            </div>
            <div
              className={`wallet_card ${
                walletInfo?.wallet?.balance === 0 ? "danger" : ""
              }`}
            >
              <div className="title">Wallet Money</div>
              <div className="count">
                <span>
                  <FaRupeeSign />
                </span>
                <p>{walletInfo?.wallet?.balance}.00 /-</p>
              </div>
            </div>
          </ProfileLayout>
        </FranchiseLayout>
      )}
    </Fragment>
  );
};

export default FranchiseDashboard;
