import React, { Fragment, useEffect, useState } from "react";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
// import { AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../styles/resultList.scss";
// import moment from "moment/moment";
import {
  clearDownloadErrors,
  clearDownloadState,
  getAllResult,
  viewCertificate,
} from "../../../redux/action/certifcateAction";
import { AiFillEye } from "react-icons/ai";
import { useNotification } from "../../../context/notificationContext";
import Loader from "../../../components/reusable/Loader";
import FranchiseLayout from "../../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../../components/franchiseLayout/SidebarLayout";

// Import Material-UI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  TableSortLabel,
  InputBase,
} from "@material-ui/core";
import moment from "moment";
import NoDataCard from "../../../components/reusable/NoDataCard";
import ButtonLoader from "../../../components/reusable/ButtonLoader";
const ResultList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  // PAGINATION FUNCTION ENDS

  const {
    loading,
    error,
    resultList: data,
  } = useSelector((state) => state.getAllResult);

  const allResult = data?.result;

  useEffect(() => {
    dispatch(getAllResult());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearDownloadErrors());
    }
  }, [dispatch, error, showNotification]);

  //   VIEW RESULT

  const {
    loading: certificateLoading,
    certificateInfo,
    error: certificateError,
  } = useSelector((state) => state.findCertificate);

  const handleViewDetails = (ele) => {
    console.log(ele);
    const roll_no = ele?.student?.rollNo;
    const course_id = ele?.course?._id;
    if (roll_no && course_id) {
      dispatch(viewCertificate(roll_no, course_id));
    } else {
      showNotification("error", "Please fill all the required fields.");
    }
  };

  useEffect(() => {
    if (certificateError) {
      showNotification("error", certificateError);
      dispatch(clearDownloadErrors());
    }

    if (typeof certificateInfo == "string") {
      dispatch(clearDownloadState());
      navigate("/franchise/view-result/certificate", {
        state: certificateInfo,
      });
    }
  }, [
    dispatch,
    showNotification,
    error,
    certificateInfo,
    certificateError,
    navigate,
  ]);

  // CREATE RESULT OF USER
  const createResult = () => {
    navigate("/franchise/create-result");
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <FranchiseLayout>
          <ProfileLayout>
            <div className="result_list">
              <div className="title">
                <h2>Student Results</h2>
                <button className="create_btn" onClick={() => createResult()}>
                  <FaPlus />
                </button>
              </div>
              {allResult?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                            // active={orderBy === "title"}
                            // direction={orderBy === "title" ? order : "asc"}
                            // onClick={() => handleSort("title")}
                            >
                              <b>Student Name</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                            // active={orderBy === "price"}
                            // direction={orderBy === "price" ? order : "asc"}
                            // onClick={() => handleSort("price")}
                            >
                              <b>Course Name</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <b>Course Duration</b>
                          </TableCell>
                          <TableCell>
                            <b>Grade / Marks Obtained</b>
                          </TableCell>
                          <TableCell>
                            <b>Created Date & Time</b>
                          </TableCell>
                          <TableCell>
                            <b>Status</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allResult
                          ?.filter((course) =>
                            course?.student?.fullName
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.student?.fullName}</TableCell>
                              <TableCell>{ele?.course?.title}</TableCell>
                              <TableCell>{ele?.duration}</TableCell>
                              <TableCell>{ele?.grade}</TableCell>
                              <TableCell>
                                {moment(ele?.createdAt).format(
                                  "MMMM-DD-YYYY [at] h:mm A"
                                )}
                              </TableCell>
                              <TableCell className="txt_center">
                                <span
                                  className={`${
                                    ele?.isActive ? "green" : "red"
                                  }`}
                                >
                                  {ele?.isActive
                                    ? "Result Out"
                                    : "Request Sent"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <div className="button_container">
                                  <Button
                                    className="edit_btn"
                                    onClick={() => handleViewDetails(ele)}
                                  >
                                    {certificateLoading ? (
                                      <ButtonLoader />
                                    ) : (
                                      <AiFillEye />
                                    )
                                    }
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={allResult?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no result added yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
        </FranchiseLayout>
      )}
    </Fragment>
  );
};

export default ResultList;
