import React, { Fragment } from 'react';
import styled from 'styled-components';
import { AiFillHome } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { breadcrumBg } from '../../assest/images';
const BreadcrumbWrapper = styled.div`
width: 100%;
position: relative;
background-position: center top;
background-size: cover;
background-repeat: no-repeat;
background-image:url(${breadcrumBg});

> .layer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: rgba(0, 13, 22, 0.45);
}
`
const BreadcrumLayer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 240px;
    text-align: left;
    >.container{
        width:100%;
        h2{
            color:#f2f2f2;
        }
        .breadcrumb-wrapper{
            z-index: 99;
            text-align:left;
            font-weight: 400;
            text-transform: capitalize;
            font-size: 13px;
            line-height: 22px;
            font-family:poppins;
            color: #f2f2f2;
            >svg{
                display: inline-block;
                vertical-align: middle;
                color: #02298A;
                font-size: 20px;
                margin-bottom:5px;
                margin-right: 5px;
                margin-top: 5px;
            }
            >span{
                display: inline-block;
                vertical-align: middle;
                line-height: 19px;
                border-bottom: 1px solid #ced7e4;
                transition: all 0.3s ease 0s;
                &:hover{
                    border-bottom:1px solid #02298A;
                }
                >a{
                    color:#f2f2f2;
                    &:hover{
                        color:#02298A;
                    }
                }
            }
            .prt-sep {
                display: inline-block;
                vertical-align: middle;
                padding-left: 10px;
                padding-right:10px;
            }
        }
    }
    
`
const Breadcrumb = ({ title }) => {
    return (
        <Fragment>
            <BreadcrumbWrapper>
                <div className='layer'></div>
                <BreadcrumLayer>
                    <div className='container'>
                        <h2 className="main_heading">
                           {title}
                        </h2>
                        <div className="breadcrumb-wrapper">
                            <AiFillHome />
                            <span>
                                <Link to="/">Home</Link>
                            </span>
                            <div className="prt-sep"> - </div>
                            {/* <span>
                                <Link to={`${}`}>team</Link>
                            </span> */}
                            {/* <div className="prt-sep"> - </div> */}
                            <span>{title}</span>
                        </div>
                    </div>
                </BreadcrumLayer>

            </BreadcrumbWrapper>
        </Fragment>
    )
}

export default Breadcrumb