import React, { Fragment } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Home from "./pages/website/Home";
import AboutUs from "./pages/website/AboutUs";
import ContactUs from "./pages/website/ContactUs";
import Login from "./pages/website/Login";
import Register from "./pages/website/Register";
import PrivacyPolicy from "./pages/terms/PrivacyPolicy";
import PaymentPolicy from "./pages/terms/PaymentPolicy";
import RefundPolicy from "./pages/terms/RefundPolicy";
import TermCondition from "./pages/terms/TermCondition";
import MyProfile from "./pages/website/MyProfile";
import Courses from "./pages/website/Courses";
import Franchise from "./pages/website/Franchise";
import { useSelector } from "react-redux";
import RedirectSpinner from "./components/reusable/RedirectSpinner";
import CourseDetails from "./pages/website/CourseDetails.js";
import Loader from "./components/reusable/Loader.js";
import NotificationPrompt from "./components/reusable/NotificationPrompt.js";
import VerifyCertificate from "./pages/website/VerifyCertificate.js";
// import Cart from "./components/cart/cart.js";
import VerificationPage from "./pages/website/VerificationPage.js";
import ScrollToTop from "./components/reusable/ScrollToTop.js";
import ErrorPage from "./pages/website/ErrorPage.js";
import Certificate from "./pages/website/Certificate.js";
import FranchiseDashboard from "./pages/franchise/FranchiseDashborad.js";
import StudentList from "./pages/franchise/students/StudentList.js";
import FranchiseProfile from "./pages/franchise/FranchiseProfile.js";
import ResultList from "./pages/franchise/result/ResultList.js";
import CreateResult from "./pages/franchise/result/CreateResult.js";
import CreateStudent from "./pages/franchise/students/CreateStudent.js";
import BatchList from "./pages/franchise/batches/BatchList.js";
import BatchCreate from "./pages/franchise/batches/CreateBatch.js";
import UpdateKyc from "./pages/franchise/UpdateKyc.js";
import TransactionList from "./pages/franchise/wallet/TransactionList.js";
import AddMoney from "./pages/franchise/wallet/AddMoney.js";
import ExamList from "./pages/franchise/exams/ExamList.js";
import ExamCreate from "./pages/franchise/exams/ExamCreate.js";
import ViewStudentList from "./pages/franchise/exams/ViewStudentList.js";
import OutMarksheet from "./pages/franchise/marksheet/OutMarksheet.js";
import CreateMarksheet from "./pages/franchise/marksheet/CreateMarksheet.js";
import ViewMarksheet from "./pages/franchise/marksheet/ViewMarksheet.js";

const App = () => {
  const { loading, isAuthenticated, userInfo } = useSelector(
    (state) => state.profile
  );

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg_color">
          <ScrollToTop />
          <NotificationPrompt />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/franchise" element={<Franchise />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/payment-policy" element={<PaymentPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-condition" element={<TermCondition />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/course-detail/:id" element={<CourseDetails />} />
            <Route path="/view-result" element={<VerifyCertificate />} />
            <Route path="/view-result/:name" element={<Certificate />} />
            <Route path="/verification" element={<VerificationPage />} />
            <Route
                path="/view-marksheet/certificate"
                element={<ViewMarksheet />}
              />


            {/* LOGGED_IN STUDENT ROUTES */}
            <Route
              path="/user"
              element={
                isAuthenticated &&
                (userInfo?.user?.role === 11 || userInfo?.user?.role === 10) ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              {/* <Route path="cart" element={<Cart />} /> */}
              <Route path="profile" element={<MyProfile />} />
            </Route>

            {/* LOGGED_IN FRANCHISE ROUTES */}
            <Route
              path="/franchise"
              element={
                isAuthenticated && userInfo?.user?.role === 12 ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              {/* <Route path="cart" element={<Cart />} /> */}
              <Route path="profile" element={<FranchiseProfile />} />
              <Route path="dashboard" element={<FranchiseDashboard />} />

              {/* students routes */}
              <Route path="student-management" element={<StudentList />} />
              <Route path="create-student" element={<CreateStudent />} />

              {/* results routes */}
              <Route path="results" element={<ResultList />} />
              <Route path="create-result" element={<CreateResult />} />

              <Route path="view-result/certificate" element={<Certificate />} />

              {/* batches routes */}
              <Route path="batches" element={<BatchList />} />
              <Route path="create-batch" element={<BatchCreate />} />


              <Route path="exams" element={<ExamList />} />
              <Route path="exam/student-list" element={<ViewStudentList />} />
              <Route path="create-exam" element={<ExamCreate />} />

              

              <Route path="document-update" element={<UpdateKyc />} />

              <Route path="wallet" element={<TransactionList />} />
              <Route path="recharge-wallet" element={<AddMoney />} />


              <Route path="out-marksheet" element={<OutMarksheet />} />
              <Route path="create-marksheet" element={<CreateMarksheet />} />
              {/* <Route path="transaction" element={<TransactionList />} /> */}
             

            </Route>


            {/* 404 ERROR PAGE */}

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      )}
    </Fragment>
  );
};

export default App;
