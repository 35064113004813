import React from 'react'
import Layout from '../../components/layouts/Layout'
import "../../styles/policy.scss";
const TermCondition = () => {
    return (
        <Layout>
            <div className='policy_wrapper'>
                <div className='container'>
                    <div className='policy_content'>
                        <div className='policy_text'>
                            <h2 className='main_heading txt_center txt_color'>
                                Terms & Condition
                            </h2>
                            <p>
                                To access certain features of the website, you
                                may be required to register for an account.
                                You agree to provide accurate and current information
                                during the registration process and to update
                                such information to keep it accurate and current.
                            </p>
                            <p>
                                All content on the website, including but not
                                limited to text, graphics, logos, images, audio clips, digital downloads, and data compilations, is the property of the
                                coaching classes website and is protected by copyright laws.
                            </p>
                            <p>
                                All content on the website, including but not
                                limited to text, graphics, logos, images, audio clips, digital downloads, and data compilations, is the property of the
                                coaching classes website and is protected by copyright laws.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default TermCondition