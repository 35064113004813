export const headerLinks = [
  {
    id: 1,
    name: "Dashboard",
    link: "/franchise/dashboard",
  },
  {
    id: 2,
    name: "Student Management",
    link: "/franchise/student-management",
  },
  {
    id: 3,
    name: "Exam Management",
    link: "/franchise/exams",
  },
  {
    id: 4,
    name: "Result Declare",
    link: "/franchise/results",
  },
  {
    id: 8,
    name: "Marksheet",
    link: "/franchise/out-marksheet",
  },
  {
    id: 5,
    name: "Batch Management",
    link: "/franchise/batches",
  },
  {
    id: 6,
    name: "Upload documents",
    link: "/franchise/document-update",
  },
  {
    id: 7,
    name: "Manage Wallet",
    link: "/franchise/wallet",
  },

  // {
  //   id: 5,
  //   name: "Student Results",
  //   link: "/franchise/student-result",
  // }

  // {
  //   id: 5,
  //   name: "Profile",
  //   link: "/user/profile",
  // },
];
