import { axiosInstance } from "../../utils/axiosInstance";
import {
  CREATE_FEANCHISE_FAIL,
  CREATE_FEANCHISE_REQUEST,
  CREATE_FEANCHISE_SUCCESS,
  QUERY_CLEAR_ERRORS,
  QUERY_CLEAR_STATE,
  USER_QUERY_FAIL,
  USER_QUERY_REQUEST,
  USER_QUERY_SUCCESS,
} from "../constant/queryConstant";

export const createFranchiseAction = (
    fullName,
    email,
    phone,
    address,
    CenterLocation,
    workExperience,
    aboutInstitute
) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_FEANCHISE_REQUEST,
    });
    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };
    const { data } = await axiosInstance.post(
      `/request-franchise`,
      {
        fullName,
        email,
        phone,
        address,
        CenterLocation,
        workExperience,
        aboutInstitute
      },
      config
    );
    dispatch({
      type: CREATE_FEANCHISE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_FEANCHISE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CONTACT TO NIOTAM TEAM
export const createQueryAction =
  (name, email, phone, message) => async (dispatch) => {
    try {
      dispatch({
        type: USER_QUERY_REQUEST,
      });
      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };
      const { data } = await axiosInstance.post(
        `/contact`,
        {
          name,
          email,
          phone,
          message,
        },
        config
      );
      dispatch({
        type: USER_QUERY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_QUERY_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  // CLEAR ERROR ACTION FUNCTION
export const clearQueryErrors = () => async (dispatch) => {
    dispatch({ type: QUERY_CLEAR_ERRORS });
  };
  
  // CLEAR ERROR ACTION FUNCTION
  export const clearQueryState = () => async (dispatch) => {
    dispatch({ type: QUERY_CLEAR_STATE });
  };
