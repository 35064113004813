import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
const LayoutWrapper = styled.div`
  min-height: 79vh;
`;
const Layout = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <LayoutWrapper>{children}</LayoutWrapper>
      <Footer />
    </Fragment>
  );
};

export default Layout;
