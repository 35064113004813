import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/layouts/Breadcrumb";
import Layout from "../../components/layouts/Layout";
import "../../styles/franchise.scss";
import CustomInput from "../../components/reusable/CustomInput";
import CustomTextarea from "../../components/reusable/CustomTextArea";
import ManualButton from "../../components/reusable/ManualButton";
import {
  MdOutlineMail,
  MdOutlineSmartphone,
  MdOutlineMyLocation,
  MdOutlineLocationOn,
} from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import {
  clearQueryErrors,
  clearQueryState,
  createFranchiseAction,
} from "../../redux/action/queryAction";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { useNotification } from "../../context/notificationContext";
// import CustomSelect from "../../components/reusable/CustomSelect";

const Franchise = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    CenterLocation: "",
    address: "",
    aboutInstitute: "",
    workExperience: "",
  });
  // GET STATE FROM FRANCHISE REDUCER
  const [, , showNotification] = useNotification();
  const { loading, queryInfo, error } = useSelector(
    (state) => state.franchiseRequest
  );

  // INPUT ON CHANGE FUNCTION
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // DESTRUCTURE ALL DATA
  const {
    fullName,
    email,
    phone,
    address,
    CenterLocation,
    workExperience,
    aboutInstitute
  } = formData;

  // QUERY FORM SUBMIT
  const handleSubmit = async () => {
    dispatch(
      createFranchiseAction(
        fullName,
        email,
        phone,
        address,
        CenterLocation,
        workExperience,
        aboutInstitute,
      )
    );
  };

  //  HANDLE ERROR AND MESSAGE AFTER SUBMIT QUERY
  useEffect(() => {
    if (queryInfo?.success) {
      showNotification("success", queryInfo?.message);
      dispatch(clearQueryState());
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
    if (error) {
      showNotification("error", error);
      dispatch(clearQueryErrors());
    }
  }, [
    dispatch,
    showNotification,
    error,
    queryInfo?.success,
    queryInfo?.message,
  ]);

  return (
    <Layout>
      <Breadcrumb title={"Our partner"} />
      <section className="franchise_wrapper">
        <div className="container">
          <div className="content_section">
            <div className="form_content">
              <h2 className="main_heading">
                Be Our <span>Partner</span>
              </h2>
              <div className="form">
                <div className="franchise_form">
                  <div className="user_details">
                    <CustomInput
                      className="franchise_input"
                      label={"Full Name"}
                      placeholder={"Your Full Name"}
                      type="text"
                      onChange={handleInputChange}
                      value={formData.fullName}
                      name="fullName"
                      icon={<FaUserAlt />}
                    />
                    <CustomInput
                      className="franchise_input"
                      label="Email"
                      placeholder={"Your Email"}
                      type="email"
                      onChange={handleInputChange}
                      value={formData.email}
                      name="email"
                      icon={<MdOutlineMail />}
                    />
                    <CustomInput
                      className="franchise_input"
                      label="Whatsapp No."
                      type="number"
                      placeholder="Your Whatsapp No."
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      icon={<MdOutlineSmartphone />}
                    />
                    <CustomInput
                      className="franchise_input"
                      label={"Address"}
                      placeholder={"Your Address"}
                      type="text"
                      onChange={handleInputChange}
                      value={formData.address}
                      name="address"
                      icon={<MdOutlineLocationOn />}
                    />
                    <CustomInput
                      className="franchise_input"
                      label="Center Location."
                      type="text"
                      placeholder="Ex. IAS kanina"
                      name="CenterLocation"
                      value={formData.CenterLocation}
                      onChange={handleInputChange}
                      icon={<MdOutlineMyLocation />}
                    />
                    <CustomInput
                      className="franchise_input"
                      label="Work Experience"
                      type="text"
                      placeholder="Ex. 2 year"
                      name="workExperience"
                      value={formData.workExperience}
                      onChange={handleInputChange}
                      icon={<MdOutlineMyLocation />}
                    />
                  </div>
                  <div className="query_details">
                    <CustomTextarea
                      label="About Institute"
                      type="text"
                      row="12"
                      placeholder="About Institute"
                      name="aboutInstitute"
                      value={formData.aboutInstitute}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="submit_form">
                  <ManualButton
                    title={loading ? <ButtonLoader /> : "Send Query"}
                    onClick={handleSubmit}
                    width={"160px"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Franchise;
