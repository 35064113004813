import { axiosInstance } from "../../utils/axiosInstance";
import {
  CLEAR_MARKSHEET_DOWNLOAD_ERROR,
  CLEAR_MARKSHEET_DOWNLOAD_STATE,
  CLEAR_MARKSHEET_UPDATE_STATE,
  CREATE_MARKSHEET_FAIL,
  CREATE_MARKSHEET_REQUEST,
  CREATE_MARKSHEET_SUCCESS,
  GET_ALL_MARKSHEET_FAIL,
  GET_ALL_MARKSHEET_REQUEST,
  GET_ALL_MARKSHEET_SUCCESS,
  VIEW_MARKSHEET_FAIL,
  VIEW_MARKSHEET_REQUEST,
  VIEW_MARKSHEET_SUCCESS,
} from "../constant/markSheetConstant";

// CREATE CERTIFICATE OF USER
export const createMarkSheet =
  (subjects, student, course, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_MARKSHEET_REQUEST,
      });
      const localStorageToken = localStorage.getItem("niotamToken")
        ? JSON.parse(localStorage.getItem("niotamToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorageToken?.token}`,
        },
      };
      const payload = {
        subjects: subjects.map((subject) => ({
          subjectName: subject.subjectName,
          marksObtain: subject.marksObtain,
          totalMarks: subject.totalMarks || "",
          practicalMarks: subject.practicalMarks,
        })),
        session: `${startDate} - ${endDate}`,
        student,
        course,
      };

      const { data } = await axiosInstance.post(
        "/create-marksheet",
        payload,
        config
      );

      dispatch({
        type: CREATE_MARKSHEET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_MARKSHEET_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL RESULT LIST
export const getAllMarkSheet = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_MARKSHEET_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-created-marksheet/${id}`, config);

    dispatch({
      type: GET_ALL_MARKSHEET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_MARKSHEET_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER CERTIFICATE DETAILS
export const viewMarkSheet = (roll_no, course_id) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_MARKSHEET_REQUEST,
    });

    const localStorageToken = localStorage.getItem("niotamToken")
      ? JSON.parse(localStorage.getItem("niotamToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/find-marksheet/student/${roll_no}/course/${course_id}`,
      config
    );

    dispatch({
      type: VIEW_MARKSHEET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VIEW_MARKSHEET_FAIL,
      payload: error.response.data.message,
    });
  }
};



// CLEAR ERROR ACTION FUNCTION
export const clearMarksheetDownloadErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_MARKSHEET_DOWNLOAD_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearMarkSheetDownloadState = () => async (dispatch) => {
  dispatch({ type: CLEAR_MARKSHEET_DOWNLOAD_STATE });
};

export const clearMarksheetUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_MARKSHEET_UPDATE_STATE });
};
