import React, { Fragment, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../../styles/batchCreate.scss";
import CustomInput from "../../../components/reusable/CustomInput";
import ManualButton from "../../../components/reusable/ManualButton";
import ButtonLoader from "../../../components/reusable/ButtonLoader";
import FranchiseLayout from "../../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../../components/franchiseLayout/SidebarLayout";
import {
  batchClearErrors,
  batchClearState,
  createBatch,
} from "../../../redux/action/batchAction";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../../context/notificationContext";
const BatchCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    title: "",
    time: "",
    studentLimit: "",
  });

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //   DESTRUCTURE ALL DATA
  const { title, time, studentLimit } = formData;

  //   CREATE COURSE ACTION
  const handleSubmit = (e) => {
    dispatch(createBatch(title, time, studentLimit));
  };

  const {
    batchData,
    error: createError,
    loading: createLoading,
  } = useSelector((state) => state.batchCreate);

  useEffect(() => {
    if (createError) {
      showNotification("error", createError);
      dispatch(batchClearErrors());
    }
    if (batchData?.success) {
      showNotification("success", batchData?.message);
      navigate("/franchise/batches");
      dispatch(batchClearState());
    }
  }, [
    createError,
    batchData?.success,
    batchData?.message,
    showNotification,
    navigate,
    dispatch,
  ]);
  return (
    <Fragment>
      <FranchiseLayout>
        <ProfileLayout>
          <div className="create_batch">
            <div className="title">
              <button
                className="create_btn"
                onClick={() => navigate("/franchise/batches")}
              >
                <IoMdArrowRoundBack />
              </button>
              <h2>Batches</h2>
            </div>
            <div className="course_form">
              <CustomInput
                label="Batch Name"
                impStar="*"
                placeholder="Batch Name"
                type="text"
                onChange={handleInput}
                value={formData.title}
                name="title"
              />
              <CustomInput
                label="Batch Time"
                impStar="*"
                placeholder="Batch Time"
                type="time"
                onChange={handleInput}
                value={formData.time}
                name="time"
              />
              <CustomInput
                label="Batch Limit"
                impStar="*"
                placeholder="Batch Limit"
                type="number"
                onChange={handleInput}
                value={formData.studentLimit}
                name="studentLimit"
              />
            </div>
            <div className="submit_form">
              <ManualButton
                className="create"
                title={createLoading ? <ButtonLoader /> : "Create"}
                onClick={handleSubmit}
                width={"110px"}
              />
            </div>
          </div>
        </ProfileLayout>
      </FranchiseLayout>
    </Fragment>
  );
};

export default BatchCreate;
