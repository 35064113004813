import {
  CLEAR_WALLET_TRANSACTION_ERROR,
  CLEAR_WALLET_TRANSACTION_STATE,
  GET_SELF_CREATED_TRANSACTION_FAIL,
  GET_SELF_CREATED_TRANSACTION_REQUEST,
  GET_SELF_CREATED_TRANSACTION_SUCCESS,
  GET_WALLET_FAIL,
  GET_WALLET_REQUEST,
  GET_WALLET_SUCCESS,
  RECHARGE_WALLET_FAIL,
  RECHARGE_WALLET_REQUEST,
  RECHARGE_WALLET_SUCCESS,
} from "../constant/walletConstant";

// FOR WALLET RECHRGE
export const addWalletMoneyReducer = (state = {}, action) => {
  switch (action.type) {
    case RECHARGE_WALLET_REQUEST:
      return {
        loading: true,
        walletInfo: {},
      };
    case RECHARGE_WALLET_SUCCESS:
      return {
        loading: false,
        walletInfo: action.payload,
      };
    case RECHARGE_WALLET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_WALLET_TRANSACTION_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_WALLET_TRANSACTION_STATE:
      return {
        loading: false,
        walletInfo: {},
        error: null,
      };

    default:
      return state;
  }
};

export const getSelfTransactionReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_SELF_CREATED_TRANSACTION_REQUEST:
        return {
          loading: true,
          transactions: {},
        };
      case GET_SELF_CREATED_TRANSACTION_SUCCESS:
        return {
          loading: false,
          transactions: action.payload,
        };
      case GET_SELF_CREATED_TRANSACTION_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_WALLET_TRANSACTION_ERROR:
        return {
          transactions: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };


  export const getWalletReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_WALLET_REQUEST:
        return {
          loading: true,
          walletInfo: {},
        };
      case GET_WALLET_SUCCESS:
        return {
          loading: false,
          walletInfo: action.payload,
        };
      case GET_WALLET_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_WALLET_TRANSACTION_ERROR:
        return {
          walletInfo: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };