import React, { Fragment, useEffect, useState } from "react";
import FranchiseLayout from "../../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../../components/franchiseLayout/SidebarLayout";
import { useNavigate } from "react-router-dom";
import { rechrge } from "../../../assest/images";
import "../../../styles/addMoney.scss";
import { IoMdArrowRoundBack } from "react-icons/io";
import CustomInput from "../../../components/reusable/CustomInput";
import ManualButton from "../../../components/reusable/ManualButton";
import ButtonLoader from "../../../components/reusable/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../../context/notificationContext";
import {
  clearWalletErrors,
  clearWalletState,
  rechargeWalletAction,
} from "../../../redux/action/walletAction";

const AddMoney = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    amount: "",
    txnId: "",
    paidBy: "",
  });

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const { amount, txnId, paidBy } = formData;
  const { error, loading, walletInfo } = useSelector(
    (state) => state.addWalletMoney
  );

  const handleSubmit = () => {
    dispatch(rechargeWalletAction(amount, txnId, paidBy));
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      clearWalletErrors();
    }
    if (walletInfo?.success) {
      showNotification(
        "success",
        "We recived your request , within 24hrs your wallet will be update"
      );
      dispatch(clearWalletState());
      navigate("/franchise/wallet");
    }
  }, [
    error,
    showNotification,
    walletInfo?.message,
    navigate,
    dispatch,
    walletInfo?.success,
  ]);

  return (
    <Fragment>
      <FranchiseLayout>
        <ProfileLayout>
          <div className="add_wallet_money">
            <div className="title">
              <button
                className="create_btn"
                onClick={() => navigate("/franchise/wallet")}
              >
                <IoMdArrowRoundBack />
              </button>
              <h2>Recharge Wallet</h2>
            </div>
            <div className="rechrge_wallet">
              <div>
                <p>Scan & pay with QR</p>
                <img src={rechrge} alt="rechrge" />
              </div>
              <section className="add_money">
                <div className="money_form">
                  <CustomInput
                    label="Paid Amount"
                    impStar="*"
                    placeholder="Enter Paid Amount"
                    type="number"
                    onChange={handleInput}
                    value={formData.amount}
                    name="amount"
                  />
                  <CustomInput
                    label="Transaction Id"
                    impStar="*"
                    placeholder="Enter Transaction Id"
                    type="text"
                    onChange={handleInput}
                    value={formData.txnId}
                    name="txnId"
                  />
                  <CustomInput
                    label="Payment Method"
                    impStar="*"
                    placeholder="Ex:- phone pe"
                    type="text"
                    onChange={handleInput}
                    value={formData.paidBy}
                    name="paidBy"
                  />
                </div>
                <div className="submit_form">
                  <ManualButton
                    className="create"
                    title={loading ? <ButtonLoader /> : "Recharge"}
                    onClick={handleSubmit}
                    width={"110px"}
                  />
                </div>
              </section>
            </div>
          </div>
        </ProfileLayout>
      </FranchiseLayout>
    </Fragment>
  );
};

export default AddMoney;
