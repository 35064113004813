import React, { Fragment, useEffect } from "react";
import "../../styles/verificationPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/reusable/Loader";
import Layout from "../../components/layouts/Layout";
import { desktop_logo } from "../../assest/images";
import {
  clearPurchaseErrors,
  clearPurchaseState,
  purchaseDoneReq,
} from "../../redux/action/purchaseAction";
import { useNotification } from "../../context/notificationContext";

const VerificationPage = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const payment_id = urlParams.get("payment_id");
  const payment_request_id = urlParams.get("payment_request_id");
  const payment_status = urlParams.get("payment_status");
  const course = urlParams.get("course");

  const [, , showNotification] = useNotification();

  // PURCHASE DONE REQUEST START
  // PURCHASE DONE REQUEST START
  const { loading, error, purchaseData } = useSelector(
    (state) => state.purchaseDone
  );

  useEffect(() => {
    dispatch(
      purchaseDoneReq(payment_request_id, payment_status, payment_id, course)
    );
  }, [dispatch, payment_request_id, payment_status, payment_id, course]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearPurchaseErrors());
    }

    if (purchaseData?.success) {
      dispatch(clearPurchaseState());
      showNotification("success", purchaseData?.message);
    }
  }, [
    dispatch,
    error,
    showNotification,
    purchaseData?.success,
    purchaseData?.message,
  ]);
  // PURCHASE DONE REQUEST ENDS
  // PURCHASE DONE REQUEST ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <section className="verification_sec">
            <div className="container">
              <div className="verification_content">
                <div className="title">
                  <h1>Verification</h1>
                </div>
                <div className="logo_text">
                  <img src={desktop_logo} alt="logo" />
                  <p>
                    We’re verifying your payment, this may take a while. Feel
                    free to leave this screen.
                  </p>
                  <p>We will sent you the confirmation email.</p>
                  <div className="payment_details">
                    <div className="smallScroll">
                      <div className="paymetDetailsHeading">
                        <h3>Payment Details</h3>
                        <p>
                          Please keep a screenshot of these details, these are
                          very important details
                        </p>
                      </div>
                      <table className="table">
                        <tbody>
                          <tr>
                            <th>Payment ID</th>
                            <td>
                              <p>{payment_id}</p>
                            </td>
                          </tr>
                          <tr>
                            <th>Payment Request ID</th>
                            <td>
                              <p>{payment_request_id}</p>
                            </td>
                          </tr>
                          <tr>
                            <th>Paid For</th>
                            <td>
                              <p>{course}</p>
                            </td>
                          </tr>
                          <tr>
                            <th>Payment Status</th>
                            <td>
                              <p
                                className={
                                  payment_status === "Credit" ? "green" : "red"
                                }
                              >
                                {payment_status === "Credit"
                                  ? "Completed"
                                  : "Failed"}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Link to="/" className="homepage_btn">
                    Home Page
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      )}
    </Fragment>
  );
};

export default VerificationPage;
