import React, { useEffect, useState } from "react";
// import SignUpLayout from "../../components/layouts/SignUpLayout";
import "../../styles/signup.scss";
import CustomInput from "../../components/reusable/CustomInput";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ManualButton from "../../components/reusable/ManualButton";
import {
  MdOutlineSmartphone,
  MdOutlineMail,
  MdLocationCity,
  MdHolidayVillage,
  MdDateRange,
  MdFamilyRestroom,
  MdAddPhotoAlternate,
} from "react-icons/md";
import { desktop_logo, userImg } from "../../assest/images";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import { TbBuildingEstate, TbMapPinCode } from "react-icons/tb";
import {
  clearErrors,
  clearState,
  userRegisterAction,
} from "../../redux/action/userAction";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import { useAuth } from "../../context/userContext";
import { indianStates } from "../../arrayFiles/stateList";
import CustomSelect from "../../components/reusable/CustomSelect";
const Register = () => {
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    city: "",
    pinCode: "",
    village: "",
    fatherName: "",
    dob: "",
    password: "",
    conPassword: "",
  });
  const [gender, setGender] = useState();
  const [state, setState] = useState();

  const [image, setImage] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(userImg);

  const handleInputChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setImage(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  console.log(image);

  const handleRemovePic = () => {
    setAvatarPreview(userImg);
    setImage("");
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const {
    fullName,
    password,
    email,
    phone,
    city,
    pinCode,
    village,
    fatherName,
    dob,
    conPassword,
  } = formData;
  const { loading, userInfo, error } = useSelector((state) => state.register);

  // REGISTER USER
  const onSubmit = async () => {
    if (password !== conPassword) {
      showNotification("error", "Password and confirm password must be same!");
    } else {
      dispatch(
        userRegisterAction(
          fullName,
          password,
          email,
          phone,
          city,
          state,
          pinCode,
          village,
          fatherName,
          dob,
          gender,
          image
        )
      );
    }
  };

  // MANAGE ERRORS AND RESPONSES
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearErrors());
    }
    if (userInfo?.success) {
      showNotification("success", userInfo?.message);
      navigate("/login");
      dispatch(clearState());
    }
    if (auth?.user?._id) {
      navigate("/user/profile");
    }
  }, [
    dispatch,
    error,
    userInfo?.message,
    userInfo?.success,
    showNotification,
    auth?.user?._id,
    navigate,
  ]);
  return (
    <section className="register_wrapper">
      <div className="container">
        <div className="register_form_section">
          <div className="logo">
            <img src={desktop_logo} alt="desktop_logo" />
          </div>
          <form>
            <CustomInput
              placeholder={"Your Full Name"}
              type={"text"}
              className="myInput"
              onChange={handleInput}
              value={formData.fullName}
              name={"fullName"}
              icon={<FaUserAlt />}
            />
            <CustomInput
              placeholder={"Your email"}
              type={"email"}
              className="myInput"
              onChange={handleInput}
              value={formData.email}
              name={"email"}
              icon={<MdOutlineMail />}
            />
            <CustomInput
              placeholder={"Your Whatsapp Number"}
              type={"number"}
              className="myInput"
              onChange={handleInput}
              value={formData.phone}
              name={"phone"}
              icon={<MdOutlineSmartphone />}
            />
            <CustomSelect
              placeholder="State"
              isMulti={false}
              value={state}
              className="myInput"
              onChange={(e) => setState(e.target.value)}
              optionArray={indianStates}
              icon={<TbBuildingEstate />}
            />
            <CustomInput
              placeholder="City"
              type="text"
              className="myInput"
              onChange={handleInput}
              value={formData.city}
              name="city"
              icon={<MdLocationCity />}
            />
            <CustomInput
              placeholder="Village"
              type="text"
              className="myInput"
              onChange={handleInput}
              value={formData.village}
              name="village"
              icon={<MdHolidayVillage />}
            />
            <CustomInput
              placeholder="Pincode"
              type="number"
              className="myInput"
              onChange={handleInput}
              value={formData.pinCode}
              name="pinCode"
              icon={<TbMapPinCode />}
            />
            <CustomInput
              placeholder="User DOB"
              type="date"
              className="myInput"
              onChange={handleInput}
              value={formData.dob}
              name="dob"
              icon={<MdDateRange />}
            />
            <CustomSelect
              placeholder="Gender"
              isMulti={false}
              value={gender}
              className="myInput"
              onChange={(e) => setGender(e.target.value)}
              optionArray={[
                {
                  value: "male",
                  option: "Male",
                },
                {
                  value: "female",
                  option: "Female",
                },
                {
                  value: "other",
                  option: "Others",
                },
              ]}
            />

            <CustomInput
              placeholder="User FatherName"
              type="text"
              className="myInput"
              onChange={handleInput}
              value={formData.fatherName}
              name="fatherName"
              icon={<MdFamilyRestroom />}
            />
            <CustomInput
              placeholder={"Password"}
              type={"password"}
              name={"password"}
              className="myInput"
              onChange={handleInput}
              value={formData.password}
              icon={<FaLock />}
            />
            <CustomInput
              placeholder={"Confirm Password"}
              type={"conPassword"}
              name={"conPassword"}
              className="myInput"
              onChange={handleInput}
              value={formData.conPassword}
              icon={<FaLock />}
            />
            <CustomInput
              placeholder="User Photo"
              type="file"
              className="myInput"
              onChange={handleInputChange}
              name="image"
              accept="image/*"
              icon={<MdAddPhotoAlternate />}
            />
            {image && (
              <div className="upload_photo">
                <div className="img_preview">
                  <img src={avatarPreview} alt="user_img" />
                  <ManualButton
                    type="button"
                    className="img_btn"
                    onClick={handleRemovePic}
                    title="Remove"
                    width={"120px"}
                  />
                </div>
              </div>
            )}
          </form>
          <div className="forget_password">
            <p>
              Already have an account ?<Link to="/login">Login</Link>
            </p>
          </div>
          <div className="submit_button">
            <ManualButton
              type={"button"}
              onClick={onSubmit}
              className={"login_btn"}
              title={loading ? <ButtonLoader /> : "Register"}
              width={"120px"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
