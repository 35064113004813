import React, { Fragment, useEffect, useState } from "react";
import FranchiseLayout from "../../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../../components/franchiseLayout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/reusable/Loader";
import DetailsPopup from "../../../components/reusable/DetailsPopup";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
import moment from "moment";
import NoDataCard from "../../../components/reusable/NoDataCard";
import { AiFillEye } from "react-icons/ai";
import "../../../styles/studentList.scss";
import { FiLoader } from "react-icons/fi";
import {
  clearFranchiseStudentErrors,
  downloadStudentInvoiceById,
  getAllFranchiseStudentAction,
} from "../../../redux/action/franchiseStudentAction";
import { MdDownload } from "react-icons/md";
import { useNotification } from "../../../context/notificationContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  InputBase,
  Button,
} from "@material-ui/core";
import { EXAMClearState } from "../../../redux/action/examAction";
const StudentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewUser, setViewUser] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [, , showNotification] = useNotification();

  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const {
    loading,
    error,
    userInfo: data,
  } = useSelector((state) => state.getAllFranchiseStudent);

  useEffect(() => {
    dispatch(getAllFranchiseStudentAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearFranchiseStudentErrors());
    }
  }, [error, dispatch]);

  //   VIEW STUDENT DETAILS
  const handleViewDetails = (details) => {
    setViewUser(!viewUser);
    setUserDetails(details);
  };

  //   CREATE NEW STUDENT
  const createUser = () => {
    navigate("/franchise/create-student");
  };

  const [userId, setUserId] = useState();

  // DOWNALOD INVOICE FUNCTION START
  const {
    loading: downloadLoading,
    error: downloadError,
    studentInfo,
  } = useSelector((state) => state.downloadStudentInvoice);

  const handleDownLoadInvoice = (data) => {
    setUserId(data?._id);
    dispatch(downloadStudentInvoiceById(data?._id,data?.courses[0]));
  };

  useEffect(() => {
    if (downloadError) {
      showNotification("error", downloadError);
      dispatch(clearFranchiseStudentErrors());
    }
    if (typeof studentInfo == "string") {
      const blob = new Blob([studentInfo], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.html";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      dispatch(EXAMClearState());
    }
  }, [dispatch, showNotification, downloadError, studentInfo]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <FranchiseLayout>
          <ProfileLayout>
            <div className="user_list">
              <div className="title">
                <h2>Student List</h2>
                <button className="create_btn" onClick={() => createUser()}>
                  <FaPlus />
                </button>
              </div>
              {data?.users?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Student Name</b>
                          </TableCell>
                          <TableCell>
                            <b>Father Name</b>
                          </TableCell>
                          <TableCell>
                            <b>Email</b>
                          </TableCell>
                          <TableCell>
                            <b>Phone</b>
                          </TableCell>
                          <TableCell>
                            <b>Created Date & Time</b>
                          </TableCell>
                          <TableCell>
                            <b>Status</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.users
                          ?.filter(
                            (course) =>
                              (course.fullName &&
                                course.fullName
                                  .toLowerCase()
                                  .includes(searchTerm.toLowerCase())) ||
                              (course?.phone && typeof course.phone === "number"
                                ? course.phone
                                    .toString()
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                : false)
                          )

                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.fullName}</TableCell>
                              <TableCell>{ele?.fatherName}</TableCell>
                              <TableCell>{ele?.email}</TableCell>
                              <TableCell>{ele?.phone}</TableCell>
                              <TableCell>
                                {moment(ele?.createdAt).format(
                                  "MMMM-DD-YYYY [at] h:mm A"
                                )}
                              </TableCell>
                              <TableCell className="txt_center">
                                <span
                                  className={`${
                                    ele?.isActive ? "green" : "red"
                                  }`}
                                >
                                  {ele?.isActive ? "Active" : "Ended"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  title="View user"
                                  onClick={() => handleViewDetails(ele)}
                                >
                                  <AiFillEye />
                                </Button>
                                <Button
                                  variant="contained"
                                  color="seconday"
                                  title="Download Invoice"
                                  onClick={() => handleDownLoadInvoice(ele)}
                                >
                                  {downloadLoading && userId === ele?._id ? (
                                    <FiLoader />
                                  ) : (
                                    <MdDownload />
                                  )}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.users?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no student register yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
          {viewUser && (
            <DetailsPopup
              title="Student Detail"
              onClick={handleViewDetails}
              details={userDetails}
            />
          )}
        </FranchiseLayout>
      )}
    </Fragment>
  );
};

export default StudentList;
