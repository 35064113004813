import {
  VIEW_CERTIFICATE_FAIL,
  VIEW_CERTIFICATE_REQUEST,
  VIEW_CERTIFICATE_SUCCESS,
  CLEAR_DOWNLOAD_ERROR,
  CLEAR_DOWNLOAD_STATE,
  CREATE_CERTIFICATE_REQUEST,
  CREATE_CERTIFICATE_SUCCESS,
  CREATE_CERTIFICATE_FAIL,
  GET_ALL_CERTIFICATE_REQUEST,
  GET_ALL_CERTIFICATE_SUCCESS,
  GET_ALL_CERTIFICATE_FAIL,
  CLEAR_RESULT_STATE,
} from "../constant/certificateConstant";

// RESULT CREATE REDUCER
export const resultCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CERTIFICATE_REQUEST:
      return {
        loading: true,
        resultData: {},
      };
    case CREATE_CERTIFICATE_SUCCESS:
      return {
        loading: false,
        resultData: action.payload,
      };
    case CREATE_CERTIFICATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_DOWNLOAD_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_RESULT_STATE:
      return {
        loading: false,
        resultData: {},
      };

    default:
      return state;
  }
};

// GET ALL RESULT LIST
export const getAllResultReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CERTIFICATE_REQUEST:
      return {
        loading: true,
        resultList: {},
      };
    case GET_ALL_CERTIFICATE_SUCCESS:
      return {
        loading: false,
        resultList: action.payload,
      };
    case GET_ALL_CERTIFICATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_DOWNLOAD_ERROR:
      return {
        resultList: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET USER CERTIFICATE REDUCER
export const findCertificateReducer = (state = {}, action) => {
  switch (action.type) {
    case VIEW_CERTIFICATE_REQUEST:
      return {
        loading: true,
        certificateInfo: {},
      };
    case VIEW_CERTIFICATE_SUCCESS:
      return {
        loading: false,
        certificateInfo: action.payload,
      };
    case VIEW_CERTIFICATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_DOWNLOAD_STATE:
      return {
        loading: false,
        userInfo: null,
      };

    default:
      return state;
  }
};
