import React, { Fragment, useEffect, useState } from "react";
import "../../styles/profile.scss";
import Layout from "../../components/layouts/Layout";
import { passwordImg, userImg } from "../../assest/images";
import { FaEdit, FaUserAlt } from "react-icons/fa";
import CustomInput from "../../components/reusable/CustomInput";
import {
  MdOutlineMail,
  MdOutlineSmartphone,
  MdPinDrop,
  MdDelete,
  MdOutlineDateRange,
  MdHolidayVillage,
  MdLocationCity,
  MdFamilyRestroom,
} from "react-icons/md";
import ManualButton from "../../components/reusable/ManualButton";
import { LuUpload } from "react-icons/lu";
import { useAuth } from "../../context/userContext";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../components/reusable/ButtonLoader";
import Loader from "../../components/reusable/Loader";
import {
  clearErrors,
  clearPasswordUpdateState,
  clearUpdateState,
  removeAvatar,
  updatePasswordAction,
  updateUserProfile,
  userProfile,
} from "../../redux/action/userAction";
import { useNotification } from "../../context/notificationContext";
import Upload from "../../components/reusable/Upload";
// import { getPurchaseList } from "../../redux/action/purchaseAction";
import { RiLockPasswordLine } from "react-icons/ri";
// import { AiFillEye } from "react-icons/ai";
import CustomSelect from "../../components/reusable/CustomSelect";
import { TbBuildingEstate, TbMapPinCode } from "react-icons/tb";
import { indianStates } from "../../arrayFiles/stateList";
import {
  clearFranchiseStudentErrors,
  downloadStudentInvoiceById,
} from "../../redux/action/franchiseStudentAction";
import { EXAMClearState } from "../../redux/action/examAction";
// import { EXAMClearErrors, getAllExamList } from "../../redux/action/examAction";

const MyProfile = () => {
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const [uploadProfileModal, setUploadProfileModal] = useState(false);
  const [, , showNotification] = useNotification();
  const { loading } = useSelector((state) => state.profile);
  const {
    loading: updateLoading,
    userInfo,
    error: updateError,
  } = useSelector((state) => state.updateProfile);
  // GET LOCALSTORAGE DATA
  const localStorageData = localStorage.getItem("niotamToken")
    ? JSON.parse(localStorage.getItem("niotamToken"))
    : "";

  const [editable, setEditable] = useState(false);
  const [activeTab, setActiveTab] = useState("password");
  const [gender, setGender] = useState(auth?.user?.gender);
  const [state, setState] = useState(auth?.user?.state);
  const [profileData, setProfileData] = useState({
    fullName: auth?.user?.fullName,
    email: auth?.user?.email,
    phone: auth?.user?.phone,
    pinCode: auth?.user?.pincode,
    city: auth?.user?.city,
    avatar: auth?.user?.avatar,
    village: auth?.user?.village,
    dob: auth?.user?.dob,
    fatherName: auth?.user?.fatherName,
    password: "",
    newPassword: "",
    conNewPassword: "",
  });

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  // DESTRUCTURE FROM DATA
  const {
    fullName,
    email,
    fatherName,
    phone,
    city,
    village,
    pinCode,
    dob,
    avatar,
    password,
    newPassword,
    conNewPassword,
  } = profileData;

  //UPDATE USER PROFILE ACTION
  const handleUpdateProfile = () => {
    dispatch(
      updateUserProfile(
        fullName,
        password,
        email,
        phone,
        city,
        state,
        pinCode,
        village,
        fatherName,
        dob,
        gender,
        avatar
      )
    );
  };

  // HANDLE MESSAGE OF UPDATE USER
  useEffect(() => {
    if (userInfo?.success) {
      setEditable(false);
      showNotification("success", userInfo?.message);
      dispatch(clearUpdateState());
      dispatch(userProfile());
    }
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearErrors());
    }
  }, [
    dispatch,
    localStorageData?.token,
    userInfo?.success,
    userInfo?.message,
    updateError,
    showNotification,
  ]);

  // UPLOAD PROFILE IMAGE
  // DELETE PROFILE PIC
  const {
    loading: removeProfileLoading,
    avatarInfo: removeProfileInfo,
    error: removeProfileError,
  } = useSelector((state) => state.removeAvatar);

  const deleteProfilePic = () => {
    dispatch(removeAvatar());
  };

  useEffect(() => {
    if (removeProfileError) {
      showNotification("error", removeProfileError);
      dispatch(clearErrors());
    }
    if (removeProfileInfo?.success) {
      showNotification("success", removeProfileInfo?.message);
      dispatch(clearUpdateState());
      dispatch(userProfile()); // GET UPDATED USER DETAILS
    }
  }, [
    dispatch,
    showNotification,
    removeProfileError,
    removeProfileInfo?.message,
    removeProfileInfo?.success,
  ]);

  // GET UPDATE PASSWORD STATE
  const {
    loading: passwordLoading,
    passwordInfo,
    error: passwordError,
  } = useSelector((state) => state.updatePassword);

  const updateUserPassword = () => {
    if (password === newPassword) {
      showNotification("error", "Password and new password cannot be same!");
    }
    if (newPassword !== conNewPassword) {
      showNotification(
        "error",
        "New password and confirm password must be same!"
      );
    }
    if (password !== newPassword && newPassword === conNewPassword) {
      dispatch(updatePasswordAction(password, newPassword));
    }
  };

  useEffect(() => {
    if (passwordError) {
      showNotification("error", passwordError);
      dispatch(clearErrors());
    }
    if (passwordInfo?.success) {
      showNotification("success", passwordInfo?.message);
      dispatch(clearPasswordUpdateState());
      dispatch(userProfile()); // GET UPDATED USER DETAILS
    }
  }, [
    dispatch,
    passwordError,
    showNotification,
    passwordInfo?.message,
    passwordInfo?.success,
  ]);

  //   const handleUploadImg = () => {};
  //   const deleteProfilePic = () => {};

  // CHANGE TABS FUNCTION START
  const handleTabActive = (query) => {
    setActiveTab(query);
  };

  // DOWNALOD INVOICE FUNCTION START
  const {
    loading: downloadLoading,
    error: downloadError,
    studentInfo,
  } = useSelector((state) => state.downloadStudentInvoice);

  const handleDownLoadInvoice = (data) => {
    dispatch(downloadStudentInvoiceById(auth?.user?._id,data?._id));
  };

  useEffect(() => {
    if (downloadError) {
      showNotification("error", downloadError);
      dispatch(clearFranchiseStudentErrors());
    }
    if (typeof studentInfo == "string") {
      const blob = new Blob([studentInfo], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "invoice.html";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      dispatch(EXAMClearState());
    }
  }, [dispatch, showNotification, downloadError, studentInfo]);

  // HANDLE DOWNLOAD HALL TICKET
  // const {
  //   loading: examLoading,
  //   error,
  //   examList,
  // } = useSelector((state) => state.getAllExam);

  // console.log(examList);

  // useEffect(() => {
  //   dispatch(getAllExamList());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (error) {
  //     showNotification("error", error);
  //     dispatch(EXAMClearErrors());
  //   }
  // }, [dispatch, error, showNotification]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="container">
            <div className="profile_wrapper">
              <div className="photo_section">
                <div className="user_img">
                  <span>
                    <button
                      className="upload_btn"
                      title="Remove Profile Picture"
                      onClick={() => deleteProfilePic()}
                    >
                      {removeProfileLoading ? <ButtonLoader /> : <MdDelete />}
                    </button>
                    <img
                      src={
                        auth?.user?.avatar?.url
                          ? auth?.user?.avatar?.url
                          : userImg
                      }
                      alt="niotam user"
                    />
                    <button
                      className="upload_btn"
                      onClick={() => setUploadProfileModal(true)}
                    >
                      <LuUpload />
                    </button>
                  </span>
                  <div className="student_card">
                    <ul>
                      <li>
                        <p>Name :</p>
                        <p>{auth?.user?.fullName}</p>
                      </li>
                      <li>
                        <p>Email :</p>
                        <p>{auth?.user?.email}</p>
                      </li>
                      <li>
                        <p>Student Id :</p>
                        <p>{auth?.user?.rollNo}</p>
                      </li>
                      <li>
                        <p>Join On :</p>
                        <p>
                          {moment(auth?.user?.createdAt).format("DD-MM-YYYY")}
                        </p>
                      </li>
                      {/* <li>
                          <p>Duration :</p>
                          <p>8 months</p>
                        </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="details_section">
                <div className="title">
                  <h2>Personal Details</h2>
                  <button
                    className={`edit_btn ${editable ? "active" : ""}`}
                    onClick={() => setEditable(!editable)}
                  >
                    <FaEdit />
                    Edit
                    {/* <IoMdAdd /> */}
                  </button>
                </div>
                <form>
                  <CustomInput
                    // label="Full Name"
                    placeholder="Your Full Name"
                    type="text"
                    onChange={handleInputChange}
                    value={
                      editable ? profileData.fullName : auth?.user?.fullName
                    }
                    name="fullName"
                    readOnly={editable ? false : true}
                    icon={<FaUserAlt />}
                    borderColor={editable ? "#02298A" : ""}
                  />
                  <CustomInput
                    // label="Email"
                    placeholder="Your Email"
                    type="email"
                    onChange={handleInputChange}
                    value={editable ? profileData.email : auth?.user?.email}
                    name="email"
                    readOnly={editable ? false : true}
                    icon={<MdOutlineMail />}
                    borderColor={editable ? "#02298A" : ""}
                  />
                  <CustomInput
                    // label="Whatsapp No."
                    type="number"
                    placeholder="Your Whatsapp No."
                    name="phone"
                    readOnly={editable ? false : true}
                    value={editable ? profileData.phone : auth?.user?.phone}
                    onChange={handleInputChange}
                    icon={<MdOutlineSmartphone />}
                    borderColor={editable ? "#02298A" : ""}
                  />
                  <CustomInput
                    // label="DOB"
                    type="date"
                    placeholder="04/XX/XXXX"
                    name="dob"
                    readOnly={editable ? false : true}
                    value={editable ? profileData.dob : auth?.user?.dob}
                    onChange={handleInputChange}
                    icon={<MdOutlineDateRange />}
                    borderColor={editable ? "#02298A" : ""}
                  />
                  <CustomInput
                    // label="City"
                    type="text"
                    placeholder="Your City"
                    name="city"
                    readOnly={editable ? false : true}
                    value={editable ? profileData.city : auth?.user?.city}
                    onChange={handleInputChange}
                    icon={<MdPinDrop />}
                    borderColor={editable ? "#02298A" : ""}
                  />
                  <CustomSelect
                    placeholder="State"
                    isMulti={false}
                    value={state}
                    className="myInput"
                    onChange={(e) => setState(e.target.value)}
                    optionArray={indianStates}
                    icon={<TbBuildingEstate />}
                  />
                  <CustomInput
                    placeholder="City"
                    type="text"
                    className="myInput"
                    onChange={handleInputChange}
                    borderColor={editable ? "#02298A" : ""}
                    value={editable ? profileData.city : auth?.user?.city}
                    name="city"
                    icon={<MdLocationCity />}
                  />
                  <CustomInput
                    placeholder="Village"
                    type="text"
                    className="myInput"
                    onChange={handleInputChange}
                    borderColor={editable ? "#02298A" : ""}
                    value={editable ? profileData.village : auth?.user?.village}
                    name="village"
                    icon={<MdHolidayVillage />}
                  />
                  <CustomInput
                    placeholder="Pincode"
                    type="number"
                    className="myInput"
                    onChange={handleInputChange}
                    borderColor={editable ? "#02298A" : ""}
                    value={editable ? profileData.pinCode : auth?.user?.pinCode}
                    name="pinCode"
                    icon={<TbMapPinCode />}
                  />
                  <CustomSelect
                    placeholder="Gender"
                    isMulti={false}
                    value={gender}
                    borderColor={editable ? "#02298A" : ""}
                    className="myInput"
                    defaultValue={gender}
                    onChange={(e) => setGender(e.target.value)}
                    optionArray={[
                      {
                        value: "male",
                        option: "Male",
                      },
                      {
                        value: "female",
                        option: "Female",
                      },
                      {
                        value: "other",
                        option: "Others",
                      },
                    ]}
                  />

                  <CustomInput
                    placeholder="User FatherName"
                    type="text"
                    className="myInput"
                    onChange={handleInputChange}
                    borderColor={editable ? "#02298A" : ""}
                    value={
                      editable ? profileData.fatherName : auth?.user?.fatherName
                    }
                    name="fatherName"
                    icon={<MdFamilyRestroom />}
                  />
                </form>
                {editable && (
                  <div className="submit_form">
                    <ManualButton
                      title={
                        updateLoading ? <ButtonLoader /> : "Update Profile"
                      }
                      onClick={handleUpdateProfile}
                      width={"160px"}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="user_tabs">
              <div className="tab_list">
                <button
                  className={`btn ${activeTab === "password" && "active"}`}
                  onClick={() => handleTabActive("password")}
                >
                  Change Password
                </button>
                {/* <button
                  className={`btn ${
                    activeTab === "apply_certificate" && "active"
                  }`}
                  onClick={() => handleTabActive("apply_certificate")}
                >
                  Apply Certificate
                </button> */}
                {auth?.user?.courses?.length && (
                  <button
                    className={`btn ${activeTab === "my_courses" && "active"}`}
                    onClick={() => handleTabActive("my_courses")}
                  >
                    My Courses
                  </button>
                )}
              </div>
              <div className="tab_data">
                {activeTab === "password" && (
                  <div className="password">
                    <div className="pssvrd_form">
                      <h2 className="heading_top">Change Password</h2>
                      <CustomInput
                        label="Current Password"
                        placeholder="Your current password"
                        type="password"
                        onChange={handleInputChange}
                        value={profileData.password}
                        name="password"
                        readOnly={false}
                        icon={<RiLockPasswordLine />}
                        borderColor={"#02298A"}
                      />
                      <CustomInput
                        label="New Password"
                        placeholder="Your New password"
                        type="password"
                        onChange={handleInputChange}
                        value={profileData.newPassword}
                        name="newPassword"
                        readOnly={false}
                        icon={<RiLockPasswordLine />}
                        borderColor={"#02298A"}
                      />
                      <CustomInput
                        label="Confirm Password"
                        placeholder="Confirm password"
                        type="password"
                        onChange={handleInputChange}
                        value={profileData.conNewPassword}
                        name="conNewPassword"
                        readOnly={false}
                        icon={<RiLockPasswordLine />}
                        borderColor={"#02298A"}
                      />
                      <div className="password_submit">
                        <ManualButton
                          title={
                            passwordLoading ? (
                              <ButtonLoader />
                            ) : (
                              "Update Password"
                            )
                          }
                          onClick={updateUserPassword}
                          width={"160px"}
                        />
                      </div>
                    </div>
                    <div className="pssvrd_bg">
                      <img src={passwordImg} alt="pssvrd_bg" />
                    </div>
                  </div>
                )}
                {activeTab === "my_courses" && (
                  <div className="purchase_history">
                    <h2 className="heading_top">My Courses</h2>
                    <div className="listing_table custonScroll">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Course Name</th>
                            <th>Price</th>
                            <th>Duration</th>
                            <th>Exam Fees</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {auth?.user?.courses?.map((item) => (
                            <tr key={item?._id}>
                              <td>{item?.title}</td>
                              <td>Rs. {item?.price} /-</td>
                              <td>{item?.sessionDuration} months</td>
                              <td>Rs. {item?.examFees} /-</td>
                              <td>
                                <p>
                                  <ManualButton
                                    title={
                                      downloadLoading ? (
                                        <ButtonLoader />
                                      ) : (
                                        "Invoice"
                                      )
                                    }
                                    width="130px"
                                    className="view_btn"
                                    onClick={() => handleDownLoadInvoice(item)}
                                  />
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* UPLOAD PROFILE PICTURE FORM START */}
            {uploadProfileModal && (
              <section className="upload_profile_pic_main">
                <Upload setUploadProfileModal={setUploadProfileModal} />
              </section>
            )}

            {/* UPLOAD PROFILE PICTURE FORM ENDS */}
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default MyProfile;
