import React, { Fragment, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../../styles/createResult.scss";
// import CustomTextarea from "../reusable/CustomTextArea";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FranchiseLayout from "../../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../../components/franchiseLayout/SidebarLayout";
import {
  getAllCourses,
  clearCourseErrors,
} from "../../../redux/action/courseAction";
import {
  createCertifcate,
  clearDownloadErrors,
  clearDownloadState,
} from "../../../redux/action/certifcateAction";
import CustomSelect from "../../../components/reusable/CustomSelect";
import CustomInput from "../../../components/reusable/CustomInput";
import ManualButton from "../../../components/reusable/ManualButton";
import ButtonLoader from "../../../components/reusable/ButtonLoader";
import { useNotification } from "../../../context/notificationContext";
import Loader from "../../../components/reusable/Loader";
import {
  clearFranchiseStudentErrors,
  getAllFranchiseStudentAction,
} from "../../../redux/action/franchiseStudentAction";
const CreateResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    rollNo: "",
    courseId: "",
    grade: "",
    startDate: "",
    endDate: "",
    duration: "",
    issueDate: "",
  });
  const { rollNo, courseId, grade, startDate, endDate, duration, issueDate } =
    formData;

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //   GET ALL STUDENT LIST
  const {
    loading,
    error,
    userInfo: data,
  } = useSelector((state) => state.getAllFranchiseStudent);
  const allUsers = data?.users;
  useEffect(() => {
    dispatch(getAllFranchiseStudentAction());
  }, [dispatch]);

  //   MAKE ARRAY OF USERS FOR SELECT BOX
  let optionArray = [];

  for (let i = 0; i < allUsers?.length; i++) {
    let optionData = {
      value: allUsers[i]?.rollNo,
      option: allUsers[i]?.fullName,
    };
    optionArray.push(optionData);
  }

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearFranchiseStudentErrors());
    }
  }, [dispatch, error, showNotification]);

  //   GET ALL COURSES
  const {
    loading: courseLoading,
    error: courseError,
    courses,
  } = useSelector((state) => state.getAllCourses);
  const allCourses = courses?.courses;
  let optionArrayCourses = [];

  for (let i = 0; i < allCourses?.length; i++) {
    let optionData = {
      value: allCourses[i]?._id,
      option: allCourses[i]?.title,
    };
    optionArrayCourses.push(optionData);
  }
  console.log(optionArrayCourses);

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(clearCourseErrors());
    }
  }, [dispatch, courseError, showNotification]);

  // CREATE RESULT
  const handleSubmit = (e) => {
    dispatch(
      createCertifcate(
        rollNo,
        courseId,
        grade,
        startDate,
        endDate,
        duration,
        issueDate
      )
    );
  };

  const {
    resultData,
    error: createError,
    loading: createLoading,
  } = useSelector((state) => state.createResult);

  //   HANDLE CREATE RESULT MESSAGES
  useEffect(() => {
    if (createError) {
      showNotification("error", createError);
      dispatch(clearDownloadErrors());
    }
    if (resultData?.success) {
      showNotification("success", resultData?.message);
      navigate("/franchise/results");
      dispatch(clearDownloadState());
    }
  }, [
    createError,
    resultData?.success,
    resultData?.message,
    showNotification,
    navigate,
    dispatch,
  ]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <FranchiseLayout>
          <ProfileLayout>
            <div className="create_result">
              <div className="title">
                <button
                  className="create_btn"
                  onClick={() => navigate("/franchise/results")}
                >
                  <IoMdArrowRoundBack />
                </button>
                <h2>Create Result</h2>
              </div>
              <div className="course_form">
                <CustomSelect
                  className="verify_input"
                  label="Select Student for which you want to apply certificate"
                  name="rollNo"
                  onChange={handleInput}
                  optionArray={optionArray}
                  impStar="*"
                  value={formData?.rollNo}
                  readOnly={false}
                  placeholder="Please select a user"
                />
                <CustomSelect
                  className="verify_input"
                  label="Select Course"
                  onChange={handleInput}
                  optionArray={optionArrayCourses}
                  impStar="*"
                  name="courseId"
                  value={formData?.courseId}
                  readOnly={false}
                  placeholder="Please select a course"
                />
                <CustomInput
                  label="Course grade"
                  impStar="*"
                  placeholder="Ex:- A++"
                  type={"text"}
                  onChange={handleInput}
                  value={formData.grade}
                  name={"grade"}
                  icon={""}
                />
                <CustomInput
                  label="Course Duration (In months)"
                  placeholder={"Course Duration"}
                  type={"number"}
                  impStar="*"
                  onChange={handleInput}
                  value={formData.duration}
                  name={"duration"}
                  icon={""}
                />
                <CustomInput
                  label="Course Start Date"
                  impStar="*"
                  placeholder="Course Start Date"
                  type="date"
                  onChange={handleInput}
                  value={formData.startDate}
                  name="startDate"
                  icon=""
                />
                <CustomInput
                  label="Course End Date"
                  impStar="*"
                  placeholder="Course End Date"
                  type="date"
                  onChange={handleInput}
                  value={formData.endDate}
                  name="endDate"
                  icon=""
                />
                <CustomInput
                  label="Certificate Issue Date"
                  impStar="*"
                  placeholder="Certificate Issue Date"
                  type="date"
                  onChange={handleInput}
                  value={formData.issueDate}
                  name="issueDate"
                  icon=""
                />
              </div>
              <div className="submit_form">
                <ManualButton
                  className="create"
                  title={createLoading ? <ButtonLoader /> : "Apply"}
                  onClick={handleSubmit}
                  width={"110px"}
                />
              </div>
            </div>
          </ProfileLayout>
        </FranchiseLayout>
      )}
    </Fragment>
  );
};

export default CreateResult;
