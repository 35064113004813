export const headerLinks = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Courses",
    link: "/courses",
  },
  {
    id: 3,
    name: "View Result",
    link: "/view-result",
  },
  {
    id: 4,
    name: "About Us",
    link: "/about-us",
  },
 
  {
    id: 5,
    name: "Contact Us",
    link: "/contact-us",
  },
 
  // {
  //   id: 5,
  //   name: "Profile",
  //   link: "/user/profile",
  // },
];
