import React, { Fragment, useEffect, useState } from "react";
import FranchiseLayout from "../../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../../components/franchiseLayout/SidebarLayout";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../../styles/createMarkSheet.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../../context/notificationContext";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import {
  clearFranchiseStudentErrors,
  getAllFranchiseStudentAction,
} from "../../../redux/action/franchiseStudentAction";
import {
  clearCourseErrors,
  getAllCourses,
} from "../../../redux/action/courseAction";
import {
  clearMarkSheetDownloadState,
  clearMarksheetDownloadErrors,
  createMarkSheet,
} from "../../../redux/action/markSheetAction";
import Loader from "../../../components/reusable/Loader";
import CustomInput from "../../../components/reusable/CustomInput";
import ManualButton from "../../../components/reusable/ManualButton";
import ButtonLoader from "../../../components/reusable/ButtonLoader";
import CustomSelect from "../../../components/reusable/CustomSelect";
// import CustomInput from "../reusable/CustomInput";
const CreateMarksheet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  const [studentId, setStudentId] = useState();
  const [courseId, setCourseId] = useState();
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
  });
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [resultData, setResultData] = useState({
    subjectName: "",
    marksObtain: "",
    totalMarks: 100,
    practicalMarks: "",
  });

  const [subjectList, setSubjectList] = useState([]);

  const handleSubjectsInput = (e) => {
    const { value, name } = e.target;
    setResultData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const {
    loading,
    error,
    userInfo: data,
  } = useSelector((state) => state.getAllFranchiseStudent);

  useEffect(() => {
    dispatch(getAllFranchiseStudentAction());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(clearFranchiseStudentErrors());
    }
  }, [error, dispatch]);
  // GET CATEGORY LIST ENDS
  const studentList = data?.users;

  let userOption = [];
  for (let i = 0; i < studentList?.length; i++) {
    let optionData = {
      value: studentList[i]?._id,
      option: `${studentList[i]?.fullName} S/O ${studentList[i]?.fatherName}`,
    };
    userOption.push(optionData);
  }

  const {
    // eslint-disable-next-line
    loading: courseLoading,
    error: courseError,
    courses,
  } = useSelector((state) => state.getAllCourses);

  // console.log(courses);
  const courseList = courses?.courses;

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(clearCourseErrors());
    }
  }, [dispatch, courseError, showNotification]);

  const handleAddSubject = () => {
    if (
      resultData?.marksObtain === "" ||
      resultData?.subjectName === "" ||
      resultData?.practicalMarks === ""
    ) {
      showNotification("error", "Please fill all required fields");
    } else {
      setSubjectList([...subjectList, resultData]);
      setResultData({
        marksObtain: "",
        subjectName: "",
        practicalMarks: "",
        totalMarks: 100,
      });
    }
  };

  const handleRemoveSubject = (index) => {
    const updatedFeatureList = subjectList.filter((_, i) => i !== index);
    // Update the state with the new array
    setSubjectList(updatedFeatureList);
  };

  const { startDate, endDate } = formData;

  //   CREATE COURSE ACTION
  const handleSubmit = (e) => {
    dispatch(
      createMarkSheet(subjectList, studentId, courseId, startDate, endDate)
    );
  };

  // HANLDE CREATE MARKSHEET ERRORS
  const {
    loading: createLoading,
    error: createError,
    markSheetData,
  } = useSelector((state) => state.markSheetCreate);

  useEffect(() => {
    if (createError) {
      showNotification("error", createError);
      dispatch(clearMarksheetDownloadErrors());
    }
    if (markSheetData?.success) {
      showNotification("success", markSheetData?.message);
      dispatch(clearMarkSheetDownloadState());
      navigate("/franchise/out-marksheet");
    }
  }, [
    markSheetData?.success,
    navigate,
    dispatch,
    showNotification,
    createError,
    markSheetData?.message,
  ]);

  const [courseOption, setCourseOption] = useState([]);

  const handleSelectStudent = (e) => {
    const value = e.target.value;
    setStudentId(value);
    const filterUser = studentList?.find((itm) => itm?._id === value); 
    if (filterUser) {
      const filteredCourses = courseList.filter((course) =>
        filterUser.courses.includes(course._id)
      );
      
      const optionsData = filteredCourses.map((course) => ({
        value: course._id,
        option: course.title,
      }));
      setCourseOption(optionsData);
    }
  };

  const [subjectArray, setSubjectArray] = useState(null);
  const handelCourseInput = (e) => {
    const data = e.target.value;
    setCourseId(data);
    const filter = courseList?.find((ele) => ele?._id === data);
    setSubjectArray(filter);
  };

  
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <FranchiseLayout>
          <ProfileLayout>
            <div className="create_marksheet">
              <div className="title">
                <button
                  className="create_btn"
                  onClick={() => navigate("/franchise/out-marksheet")}
                >
                  <IoMdArrowRoundBack />
                </button>
                <h2>Create Marksheet</h2>
              </div>
              <div className="course_form">
                <CustomSelect
                  label="Select Student"
                  impStar="*"
                  isMulti={false}
                  value={studentId}
                  onChange={(e) => handleSelectStudent(e)}
                  optionArray={userOption}
                />
                <CustomSelect
                  label="Select Course"
                  impStar="*"
                  isMulti={false}
                  value={courseId}
                  onChange={(e) => handelCourseInput(e)}
                  optionArray={courseOption}
                />
                <CustomInput
                  label="Start Date"
                  impStar="*"
                  type="date"
                  onChange={handleInput}
                  value={formData.startDate}
                  name="startDate"
                />
                <CustomInput
                  label="End Date"
                  impStar="*"
                  type="date"
                  onChange={handleInput}
                  value={formData.endDate}
                  name="endDate"
                />

                <div className="subject_container">
                  <h4>
                    Add Subject Marks (Total Marks will calculate out of 100)
                  </h4>
                  <div className="mark_result">
                  <div className="input_box">
                      <label>Subject Name</label>
                      <select
                        value={resultData.subjectName}
                        name="subjectName"
                        onChange={(e) => handleSubjectsInput(e)}
                      >
                        <option value="">Select Subject</option>
                        {subjectArray?.subjectList?.length > 0 &&
                          subjectArray.subjectList.map((ele, idx) => (
                            <option value={ele} key={idx}>
                              {ele}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="input_box">
                      <div>
                        <label>Marks Obtain</label>
                        <input
                          type="number"
                          value={resultData?.marksObtain}
                          name="marksObtain"
                          onChange={(e) => handleSubjectsInput(e)}
                          placeholder="Marks should be in number only"
                        />
                      </div>
                    </div>
                    <div className="input_box">
                      <div>
                        <label>Practical Marks</label>
                        <input
                          type="number"
                          value={resultData?.practicalMarks}
                          name="practicalMarks"
                          onChange={(e) => handleSubjectsInput(e)}
                          placeholder="Marks should be in number only"
                        />
                      </div>
                    </div>
                    {/* <div className="input_box">
                      <div>
                        <label>Total Marks</label>
                        <input
                          type="number"
                          value={resultData?.totalMarks}
                          name="totalMarks"
                          disabled
                          readOnly
                          onChange={(e) => handleSubjectsInput(e)}
                          placeholder="Marks should be in number only"
                        />
                      </div>
                    </div> */}
                    <div className="input_box">
                      <button type="button" onClick={() => handleAddSubject()}>
                        <FaPlus />
                      </button>
                    </div>
                  </div>
                  {subjectList.length > 0 && (
                    <div className="subject_list">
                      {subjectList?.map((itm, idx) => (
                        <div key={idx}>
                          <p>
                            <span>Subject : {itm?.subjectName}</span>
                            <span>Theory : {itm?.marksObtain}</span>
                            <span>Practical : {itm?.practicalMarks}</span>
                          </p>
                          <button onClick={() => handleRemoveSubject(idx)}>
                            <RxCross2 />
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="submit_form">
                <ManualButton
                  className="create"
                  title={createLoading ? <ButtonLoader /> : "Create"}
                  onClick={handleSubmit}
                  width={"110px"}
                />
              </div>
            </div>
          </ProfileLayout>
        </FranchiseLayout>
      )}
    </Fragment>
  );
};

export default CreateMarksheet;
