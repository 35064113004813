// DOWNLOAD CERTIFICATE CONSTANTS
export const CREATE_CERTIFICATE_REQUEST = "CREATE_CERTIFICATE_REQUEST";
export const CREATE_CERTIFICATE_SUCCESS = "CREATE_CERTIFICATE_SUCCESS";
export const CREATE_CERTIFICATE_FAIL = "CREATE_CERTIFICATE_FAIL";

export const VIEW_CERTIFICATE_REQUEST = "VIEW_CERTIFICATE_REQUEST";
export const VIEW_CERTIFICATE_SUCCESS = "VIEW_CERTIFICATE_SUCCESS";
export const VIEW_CERTIFICATE_FAIL = "VIEW_CERTIFICATE_FAIL";

export const EDIT_CERTIFICATE_REQUEST = "EDIT_CERTIFICATE_REQUEST";
export const EDIT_CERTIFICATE_SUCCESS = "EDIT_CERTIFICATE_SUCCESS";
export const EDIT_CERTIFICATE_FAIL = "EDIT_CERTIFICATE_FAIL";

export const GET_ALL_CERTIFICATE_REQUEST = "GET_ALL_CERTIFICATE_REQUEST";
export const GET_ALL_CERTIFICATE_SUCCESS = "GET_ALL_CERTIFICATE_SUCCESS";
export const GET_ALL_CERTIFICATE_FAIL = "GET_ALL_CERTIFICATE_FAIL";

export const CLEAR_DOWNLOAD_ERROR = "CLEAR_DOWNLOAD_ERROR";
export const CLEAR_RESULT_STATE = "CLEAR_RESULT_STATE";
export const CLEAR_DOWNLOAD_STATE = "CLEAR_DOWNLOAD_STATE";