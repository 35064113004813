import React, { Fragment, useEffect, useState } from "react";
import FranchiseLayout from "../../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../../components/franchiseLayout/SidebarLayout";
import Loader from "../../../components/reusable/Loader";
import NoDataCard from "../../../components/reusable/NoDataCard";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../styles/examList.scss";
import moment from "moment/moment";
import { useNotification } from "../../../context/notificationContext";
// Import Material-UI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  InputBase,
  Button,
} from "@material-ui/core";
import {
  EXAMClearErrors,
  getAllExamList,
} from "../../../redux/action/examAction";
import { AiFillEye } from "react-icons/ai";

const ExamList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  // PAGINATION FUNCTION ENDS

  const {
    loading,
    error,
    examList: data,
  } = useSelector((state) => state.getAllExam);

  useEffect(() => {
    dispatch(getAllExamList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(EXAMClearErrors());
    }
  }, [dispatch, error, showNotification]);

  const createExam = () => {
    navigate("/franchise/create-exam");
  };

  const handleViewDetails = (data) => {
    navigate("/franchise/exam/student-list", { state: data });
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <FranchiseLayout>
          <ProfileLayout>
            <div className="exam_list">
              <div className="title">
                <h2>All Exam</h2>
                <button className="create_btn" onClick={() => createExam()}>
                  <FaPlus />
                </button>
              </div>
              {data?.hallTickets?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Exam Title</b>
                          </TableCell>
                          <TableCell>
                            <b>Exam Time</b>
                          </TableCell>
                          <TableCell>
                            <b>Exam Date</b>
                          </TableCell>
                          <TableCell>
                            <b>Location</b>
                          </TableCell>
                          <TableCell>
                            <b>Batch</b>
                          </TableCell>
                          <TableCell>
                            <b>Course</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.hallTickets
                          ?.filter((course) =>
                            course.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.title}</TableCell>
                              <TableCell>
                                {moment(ele?.time).format("hh:mm")}
                              </TableCell>
                              <TableCell>
                                {moment(ele?.date).format("DD-MM-yyyy")}
                              </TableCell>
                              <TableCell>{ele?.location}</TableCell>
                              <TableCell>{ele?.batch?.title}</TableCell>
                              <TableCell>{ele?.course?.title}</TableCell>
                              <TableCell className="txt_center">
                                <Button
                                  title="View Student List"
                                  className="edit_btn"
                                  onClick={() => handleViewDetails(ele)}
                                >
                                  <AiFillEye />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.hallTickets?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no exam created yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
        </FranchiseLayout>
      )}
    </Fragment>
  );
};

export default ExamList;
