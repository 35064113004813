import React, { Fragment, useEffect, useState } from "react";
import FranchiseLayout from "../../components/franchiseLayout/FranchiseLayout";
import ProfileLayout from "../../components/franchiseLayout/SidebarLayout";
import CustomInput from "../../components/reusable/CustomInput";
import { userImg } from "../../assest/images";
import ManualButton from "../../components/reusable/ManualButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/kycUpdate.scss";
import {
  kycClearErrors,
  kycClearState,
  updateKyc,
} from "../../redux/action/kycAction";
import { useNotification } from "../../context/notificationContext";
import ButtonLoader from "../../components/reusable/ButtonLoader";

const UpdateKyc = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, , showNotification] = useNotification();
  const [institueName, setInstitueName] = useState("");
  const [image, setImage] = useState();
  const [avatarPreview, setAvatarPreview] = useState(userImg);

  //   signature states
  const [logo, setLogo] = useState();
  const [avatarLogo, setAvatarLogo] = useState(userImg);

  const handleInputChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setImage(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleInputLogoChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarLogo(reader.result);
        setLogo(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = () => {
    dispatch(updateKyc(institueName, logo, image));
  };

  const { loading, error, kycData } = useSelector((state) => state.kycUpdate);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(kycClearErrors());
    }
    if (kycData?.success) {
      showNotification("success", kycData?.message);
      dispatch(kycClearState());
      navigate("/franchise/dashboard");
    }
  }, [
    error,
    showNotification,
    kycData?.success,
    dispatch,
    kycData?.message,
    navigate,
  ]);

  return (
    <Fragment>
      <FranchiseLayout>
        <ProfileLayout>
          <div className="kyc_media">
            <div className="title">
              <h2>Document Update</h2>
              {/* <button className="create_btn" onClick={() => createUser()}>
                <FaPlus />
              </button> */}
            </div>
            <div className="kyc_form">
              <CustomInput
                label="Your Institute Name"
                placeholder="ETC coaching classes"
                type="text"
                name="institueName"
                value={institueName}
                onChange={(e) => setInstitueName(e.target.value)}
              />
              <CustomInput
                label="Owner Signature(max ratio with 40px*20px"
                impStar="*"
                placeholder=""
                type="file"
                name="image"
                accept="image/*"
                onChange={handleInputChange}
              />
              <CustomInput
                label="Exam Controller Stamp"
                impStar="*"
                placeholder=""
                type="file"
                name="logo"
                accept="image/*"
                onChange={handleInputLogoChange}
              />
            </div>

            <div className="documents">
              <div className="image_preview">
                <p>Signature</p>
                <img src={avatarPreview} alt="user_img" />
              </div>
              <div className="image_preview">
                <p>Stamp</p>
                <img src={avatarLogo} alt="user_img" />
              </div>
            </div>

            <div className="submit_form">
              <ManualButton
                className="create"
                title={loading ? <ButtonLoader /> : "Upload"}
                onClick={handleSubmit}
                width={"110px"}
              />
            </div>
          </div>
        </ProfileLayout>
      </FranchiseLayout>
    </Fragment>
  );
};

export default UpdateKyc;
