import React, { Fragment } from 'react';
import { desktop_logo } from '../../assest/images';
import "../../styles/footer.scss";
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <Fragment>
            <div className='footer_wrapper'>
                <div className='container'>
                    <div className='footer_content'>
                        <div className='logo_wrap'>
                            <img src={desktop_logo} alt='desktop_logo' />
                        </div>
                        <div className='useful_links'>
                            <h4>
                                Useful Links
                            </h4>
                            <ul>
                                <li>
                                    <Link to="/about-us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/franchise">Franchise</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Support</Link>
                                </li>
                                <li>
                                    <Link to="/terms-condition">Terms & Condtion</Link>
                                </li>
                            </ul>
                        </div>
                        <div className='policy_links'>
                            <h4>
                                Our Policy
                            </h4>
                            <ul>
                                <li>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/payment-policy">Payment Policy</Link>
                                </li>

                                <li>
                                    <Link to="/refund-policy">Refund Policy</Link>
                                </li>
                            </ul>
                        </div>
                        <div className='policy_links'>
                            <h4>
                                Social Media
                            </h4>
                            <ul>
                                <li>
                                    <Link to="/">Facebook</Link>
                                </li>
                                <li>
                                    <Link to="/">Instagram</Link>
                                </li>

                                <li>
                                    <Link to="/">Telegram</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='copy_right'>
                    <p>
                        All Rights Reserved @ Niotam
                    </p>
                </div>

            </div>
        </Fragment>
    )
}

export default Footer