import React from 'react';
import styled from 'styled-components';
const InputWrapper = styled.div`
  margin-bottom: 25px;
  font-family:nunito;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #003a66;
    > .impStar {
      color: red;
      font-size: 18px;
    }
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }
  .input_icon {
    position: relative;
    > .icon {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #003a66;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }

    > .eyeIcon {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media screen and (max-width: 575px) {
        height: 40px;
        width: 40px;
        font-size: 13px;
      }
      > svg {
        color: #003a66;
        z-index: 1;
        position: absolute;
        width: 40%;
        height: 40%;
      }
    }
  }
  .input_text {
    display: block;
    width: 100%;
    height: 50px;
    border:1px solid #B9B9B9;
    border-radius:40px;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    color: #333;
    background-color:#f2f2f2;
    font-size: 14px;
    font-family:nunito;
    @media screen and (max-width: 575px) {
      height: 40px;
      font-size: 13px;
    }
  }
`;
const CustomInput = ({
    label,
    icon,
    type,
    impStar,
    onChange,
    placeholder,
    name,
    value,
    readOnly,
    id,
    eyeIcon,
    setshowPassword,
    fullWidth,
    borderColor,
    className
}) => {

  return (
    <InputWrapper className={className}  style={{ width: fullWidth ? "100%" : "" }}>
    {label && (
        <label>
          {label} {impStar && <abbr className="impStar">{impStar}</abbr>}
        </label>
      )}
      <div className="input_icon">
        {icon && <span className="icon">{icon}</span>}
        <input
          className="input_text"
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          value={value}
          readOnly={readOnly}
          id={id}
          style={{
            border: borderColor && `1px solid ${borderColor}`,
            paddingLeft: icon && "50px",
          }}
        />
        {eyeIcon && (
          <span
          className="eyeIcon"
          onClick={() => setshowPassword((prevState) => !prevState)}
        >
          {eyeIcon}
        </span>
        )}
      </div>
    </InputWrapper>
  )
}

export default CustomInput